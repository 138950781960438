import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate
} from "react-router-dom";
import List from './containers/List';
import NotFound from './containers/NotFound';
import CreateApplication from './containers/CreateApplication';
import './App.scss'
import LoginPage from "./containers/LoginPage";
import { EditApplicationPage } from "./containers/EditApplications";
import { ViewApplicationPage } from "./containers/ViewApplication";
import ProtectedRoute from "./routing/ProtectedRoute";
import VerifyApplicationPage from './containers/moderation/VerifyApplication'
import { ModerationsList } from './containers/moderation/List'
import { ModerationsDetails } from './containers/moderation/Details'
import { DonationListPage } from "./containers/donation/List";
import { DonationApplicationPage } from "./containers/donation/Application";
import { StartPage } from "./containers/StartPage";
import { DonationDetailApplicationPage } from "./containers/donation/Details";
import { DonationImportPage } from "./containers/donation/Import";

export default function App() {

	return (
		<Router>
			<Routes>
				<Route exact path="/"
					element={<StartPage />} />
				<Route exact path="/login/"
					element={<LoginPage />} />
				<Route exact path="/123/"
					element={<NotFound />} />
				<Route path="*"
					element={<Navigate to="/applications/" />} />
				<Route element={<ProtectedRoute />}>
					<Route exact path="/applications/"
						element={<List />} />
					<Route exact path="/applications/create/"
						element={<CreateApplication />} />
					<Route path={`/applications/edit/:id/`}
						element={<EditApplicationPage />} />
					<Route path={`/applications/detail/:id/`}
						element={<ViewApplicationPage />} />
					<Route path={`/moderations/`}
						element={<ModerationsList />} />
					<Route path={`/moderation/detail/:id/`}
						element={<ModerationsDetails />} />
					<Route path={`/moderation/application/:id/`}
						element={<VerifyApplicationPage />} />
				</Route>
				<Route path={`/donation/list/`}
					element={<DonationListPage />} />
				<Route path={`/donation/import/`}
					element={<DonationImportPage />} />
				<Route path={`/donation/:id/`}
					element={<DonationApplicationPage />} />
				<Route path={`/donation/detail/:id/`}
					element={<DonationDetailApplicationPage />} />
			</Routes>
		</Router>
	);
}


