// delete this file?

import React, { useRef, useEffect, useState } from 'react';
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import '../../assets/scss/map.scss';
import axios from 'axios';
import { URLS } from '../../routing/urls';
import * as turf from '@turf/turf';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../../assets/scss/tabs.scss';
import '../../assets/scss/comment.scss';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export default function ValidateApplicationPage() {

    const map = useRef(null);
    const [key, setKey] = useState('applicationTab');

    const getDeepStatePolygons = () => {
        axios.get(URLS.DEEP_STATE)
            .then(geojson => {
                setDeepStatePolygons(geojson.data.map);
                setBufferPolygons(geojson.data.map);
            })
            .catch(err => {
                console.log(err)
            })
    }

    const setDeepStatePolygons = (geojson) => {
        map.current.on('load', () => {
            map.current.addSource('deepStatePolygons', {
                type: 'geojson',
                data: geojson
            });

            map.current.addLayer({
                'id': 'deepStatePolygons-layer',
                'type': 'fill',
                'source': 'deepStatePolygons',
                'paint': {
                    'fill-color': ['get', 'fill'],
                    'fill-opacity': 0.5
                }
            });
        })
    }

    const setBufferPolygons = (geojson) => {
        let territory = { type: 'FeatureCollection', features: [] };

        geojson.features.forEach(feature => {
            let name = feature.properties.name;
            if (name.startsWith('Звільнено') ||
                name.startsWith('ОРДЛО') ||
                name.startsWith('Ситуація невідома') ||
                name.startsWith('Окупован')) {
                territory.features = [...territory.features, feature];
            }
        })
        territory = turf.combine(territory);
        let buffered = turf.buffer(territory, 50, { units: 'kilometers' });

        map.current.on('load', () => {
            map.current.addSource('bufferPolygons', {
                type: 'geojson',
                data: buffered
            });

            map.current.addLayer({
                'id': 'bufferPolygons-layer',
                'type': 'line',
                'source': 'bufferPolygons',
                'paint': {
                    'line-color': '#000000',
                    'line-opacity': 0.5,
                    'line-dasharray': [2, 1],
                }
            });
        })
    }

    const CommentBlock = (props) => {
        let comment = <span className="comment">Залишити коментар</span>
        if (props.comments.length > 0) {
            comment = <div className="comment">
                <span className='green-circle'></span>
                <span>{props.comments.length} коментар</span>
            </div>
        }
        return comment;
    }

    const EdrBlock = (props) => {
        return (
            <div className="card">
                <div className="card-header">
                    <span>ЄДР</span>
                    <CommentBlock comments={[]} />
                </div>
                <div className="card-body app-validation-card">
                    <div>
                        <span>Назва</span>
                        <span>ТОВ “Луги-2”</span>
                    </div>
                    <div>
                        <span>Єдрпоу</span>
                        <span>12312312</span>
                    </div>
                    <div>
                        <span>Адреса</span>
                        <span>м. Львів, вул. Личаківська, 37</span>
                    </div>
                    <div>
                        <span>квед</span>
                        <span>01.01 шось</span>
                    </div>
                    <div>
                        <span>дата реєстрації</span>
                        <span>11.07.2022</span>
                    </div>
                </div>
            </div>
        )
    }

    const LandBankBlock = () => {
        const mapContainer = useRef(null);

        const [lng, setLng] = useState(32);
        const [lat, setLat] = useState(49);
        const [zoom, setZoom] = useState(4);

        useEffect(() => {
            if (map.current) return; // initialize map only once
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/light-v10',
                center: [lng, lat],
                zoom: zoom,
            });
            getDeepStatePolygons();
        });

        useEffect(() => {
            if (!map.current) return; // wait for map to initialize
            map.current.on('move', () => {
                setLng(map.current.getCenter().lng.toFixed(4));
                setLat(map.current.getCenter().lat.toFixed(4));
                setZoom(map.current.getZoom().toFixed(2));
            });
        });

        return (
            <div className="card">
                <div className="card-header">
                    <span>Земельний банк</span>
                    <CommentBlock comments={[]} />
                </div>
                <div className="card-body w-100">
                    <div ref={mapContainer} className="map-container" />
                </div>
            </div>
        )
    }

    const ExecProceedBlock = () => {
        return (
            <div className="card">
                <div className="card-header">
                    <span>Виконавчі провадження</span>
                    <CommentBlock comments={[]} />
                </div>
                <div className="card-body">
                    123
                </div>
            </div>
        )
    }

    const DebtorsBlock = () => {
        return (
            <div className="card">
                <div className="card-header">
                    <span>Реєстр боржників</span>
                    <CommentBlock comments={[]} />
                </div>
                <div className="card-body">
                    123
                </div>
            </div>
        )
    }

    const AgroReceiptsBlock = () => {
        return (
            <div className="card">
                <div className="card-header">
                    <span>Аграрні розписки</span>
                    <CommentBlock comments={[1]} />
                </div>
                <div className="card-body">
                    123
                </div>
            </div>
        )
    }

    const ApplicationTab = () => {
        return (

            <form>
                <EdrBlock />
                <LandBankBlock />
                <ExecProceedBlock />
                <DebtorsBlock />
                <AgroReceiptsBlock />
            </form>
        )
    }

    const BudgetTab = () => {
        return (
            <span>budget</span>
        )
    }

    const TranslateTab = () => {
        return (
            <span>translate</span>
        )
    }

    return (
        <>
            <Header />
            <main>
                <div className="container">
                    <div className="page-title">Заявка</div>
                    <Tabs
                        id="validation-tab"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="applicationTab" title="Заявка">
                            <ApplicationTab />
                        </Tab>
                        <Tab eventKey="budgetTab" title="Бюджет">
                            <BudgetTab />
                        </Tab>
                        <Tab eventKey="translateTab" title="Переклади">
                            <TranslateTab />
                        </Tab>
                    </Tabs>
                </div>
            </main>
            <Footer />
        </>
    )
}