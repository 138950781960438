import axios from 'axios';
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import React, { useEffect, useState } from 'react';
import { axiosWrapper } from '../../components/Helper';
import { URLS } from '../../routing/urls';
import { Card } from '../../components/Application/Card';
import { useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loader-spinner';
import { ToastContainer } from 'react-toastify';
import LazyLoad from 'react-lazyload';
import { Tab, Tabs } from 'react-bootstrap';

export const ModerationsList = () => {
    const { t } = useTranslation();
    const [applications, setApplications] = useState([]);
    const [moderatedApplications, setModeratedApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0)

    const getModerationsList = () => {
        axiosWrapper(() => {
            axios.get(`${URLS.APPLICATIONS_LIST}?filter=not_moderated&ordering=created_at`)
                .then(res => {
                    setApplications(res.data);
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    console.log(err)
                })
        })
    }

    const getModeratedApplications = () => {
        axiosWrapper(() => {
            axios.get(`${URLS.APPLICATIONS_LIST}?show_moderated=true&ordering=created_at`)
                .then(res => {
                    const applications = res.data;
                    setModeratedApplications(applications);
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                })
        })
    }

    useEffect(() => {
        getModerationsList();
        getModeratedApplications();
    }, []);

    const ModeratedApplicationList = () => {
        let cards = moderatedApplications.map((application) => {
            return (
                <LazyLoad once={application.once}
                    height={200} offset={[-100, 0]}
                    key={application.id}>
                    <Card
                        application={application}
                        moderation={application.id}
                    />
                </LazyLoad>

            )
        })
        return (
            <>
                <div className="list-page-header">
                    <span className="page-title">{t('moderation')}</span>
                </div>
                <div className="card-list">{cards}</div>
            </>
        )
    }

    const ApplicationList = () => {
        let cards = applications.map((application) => {
            return (
                <LazyLoad once={application.once}
                    height={200} offset={[-100, 0]}
                    key={application.id}>
                    <Card
                        application={application}
                        moderation={application.id}
                    />
                </LazyLoad>

            )
        })
        return (
            <>
                <div className="list-page-header">
                    <span className="page-title">{t('moderation')}</span>
                </div>
                <div className="card-list">{cards}</div>
            </>
        )
    }

    return (
        <>
            <Header applicationCount={applications.length} />
            <main>
                <div className="container">
                    <Tabs
                        id="moderations-tab"
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="0" title={t('inProcess')}>
                            <ApplicationList applications={applications} />
                        </Tab>
                        <Tab eventKey="1" title={t('moderated')}>
                            <ModeratedApplicationList />
                        </Tab>
                    </Tabs>
                </div>
                <div className={`loader-center ${loading ? '' : 'd-none'}`}>
                    <TailSpin color="grey" ariaLabel="loading-indicator" />
                </div>
                <ToastContainer />
            </main>
            <Footer />
        </>
    )
}