export const URLS = {
    APPLICATIONS_LIST: `applications/`,
    APPLICATION_DETAILS: `applications/detail/`,
    COMPANIES: `companies/`,
    IMAGES: `images/`,
    LOGIN: `login/`,
    LOGIN_CONFIRM: `login-confirm/`,

    MODERATIONS: 'moderations/',
    MODERATIONS_DETAIL: 'moderation/detail/',

    TRANSLATIONS: 'translations/',
    TRANSLATIONS_DETAIL: 'translations/detail/',

    COMMENTS: 'comments/',
    COMMENTS_DETAIL: 'comments/detail/',

    VERIFY: `auth/verify/`,
    REFRESH: `auth/refresh/`,

    DEEP_STATE: 'https://deepstatemap.live/api/history/last',
    NOVA_POST_API: 'https://api.novaposhta.ua/v2.0/json/',

    USER_INFO: 'user-info/',

    FAMI: 'fami/',

    DONATION: 'donation/'
}