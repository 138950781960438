import { CommentModal } from '../../../components/Popup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Cleave from 'cleave.js/react';

export const EdrBlock = (props) => {
    const { t } = useTranslation();
    let moderation = props.moderation;
    let raw_details = moderation.application?.company.raw_details;
    let address = raw_details?.address || '-';
    let activities = raw_details?.economicActivities?.map((activity, index) => {
        return <div key={index}>{activity.code} {activity.description}</div>
    })

    let registrationDate = () => {
        let registrationBeforeLaw = raw_details?.registrationBeforeLaw?.entryDate;
        let registrationAfterLaw = raw_details?.registrationAfterLaw?.entryDate;
        if (!registrationBeforeLaw && !registrationAfterLaw) return '';
        if (registrationAfterLaw) {
            return moment(registrationAfterLaw).format('l');
        }
        if (registrationBeforeLaw) {
            return moment(registrationBeforeLaw).format('l');
        }
    }
    return (
        <div className="card">
            <div className="card-header">
                <span>{t('edr')}</span>
                <CommentModal
                    comments={props.comments}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    block={'edr'}
                    moderationId={props.moderation.id}
                    setLoading={props.setLoading}
                    moderationView={props.moderationView}
                />
            </div>
            <div className="card-body app-validation-card w-100">
                <div className='block'>
                    <fieldset className='form-sub-block'>
                        <legend>{t('chief')}</legend>
                        <div className="sub-block">
                            <span>{t('fullname')}</span>
                            <span>{moderation?.application?.chief_name || '-'}</span>
                        </div>
                        <div className="sub-block">
                            <span>{t('phoneNumber')}</span>
                            <span>{moderation?.application?.chief_phone_number || '-'}</span>
                        </div>
                    </fieldset>
                </div>
                <div className='block'>
                    <div className='sub-block'>
                        <span>{t('companyName')}</span>
                        <span>{moderation?.application?.company?.name || '-'}</span>
                    </div>
                </div>
                <div className='block'>
                    <div className='sub-block'>
                        <span>{t('edrpouCode')}</span>
                        <span>{moderation?.application?.company?.edrpou || '-'}</span>
                    </div>
                </div>
                <div className='block'>
                    <div className='sub-block'>
                        <span>{t('location')}</span>
                        <span>{address || '-'}</span>
                    </div>
                    <div className='form-group sub-block'>
                        <input type="checkbox" name="address"
                            id="address"
                            checked={props.checkboxes.address}
                            onChange={(event) => props.handleCheckboxChange(event)} />
                        <label htmlFor='address'>{t('yes')}</label>
                    </div>
                </div>
                <div className='block'>
                    <div className='sub-block'>
                        <span>{t('kved')}</span>
                        <span>{activities}</span>
                    </div>
                    <div className='form-group sub-block'>
                        <input type="checkbox" name="economicActivities"
                            id="economicActivities"
                            checked={props.checkboxes.economicActivities}
                            onChange={(event) => props.handleCheckboxChange(event)} />
                        <label htmlFor='economicActivities'>{t('yes')}</label>
                    </div>
                </div>
                <div className='block'>
                    <div className='sub-block'>
                        <span>{t('registrationDate')}</span>
                        <span>{registrationDate()}</span>
                    </div>
                    <div className='form-group sub-block'>
                        <input type="checkbox" name="registryDate"
                            id="registryDate"
                            checked={props.checkboxes.registryDate}
                            onChange={(event) => props.handleCheckboxChange(event)} />
                        <label htmlFor='registryDate'>{t('yes')}</label>
                    </div>
                </div>
            </div>
        </div>
    )
}