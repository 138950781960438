import { useState } from "react";
import { useTranslation } from "react-i18next";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import '../../assets/scss/images.scss';

export const LoadImages = (props) => {
    const { t } = useTranslation();
    const [images, setImages] = useState(props.imagesData || []);
    const maxNumber = 10;

    const ExistingFiles = () => {
        if (props.action === 'create') return;
        if (props.files.length === 0) return;
        return (
            props.files.map((file, index) => (
                <div key={index} className="image-item">
                    <a href={file}>
                        <img src={file} alt={file} />
                        <DeleteExistingImageButton
                            action={props.action}
                            deleteExistingImage={props.deleteExistingImage}
                            file={file}
                            imageIds={props.imageIds}
                            index={index}
                        />
                    </a>
                </div>
            ))
        )
    }

    const onChange = (imageList, addUpdateIndex) => {
        addUpdateIndex?.forEach(index => {
            imageList[index].file.data_url = imageList[index].data_url;
        })

        setImages(imageList)
        props.handleFileChange(imageList);
    };

    const onError = (errors) => {
        if (errors.maxNumber) {
            toast.error(t('maxNumberError'))
        }
        if (errors.maxFileSize) {
            toast.error(t('maxSizeError'));
        }
        if (errors.acceptType) {
            toast.error(t('imageTypeError'));
        }
    }

    const UploadSpace = (props) => {
        if (props.action === 'view') return;
        return (
            <div
                className='upload-space'
                style={props.isDragging ? { background: "lightgrey" } : null}
                {...props.dragProps}
            >
                {t('dragOr')}
                <span className="upload-button"
                    onClick={props.onImageUpload}> {t('upload')} </span>
                {t('photo')}
            </div>
        )
    }

    const DeleteExistingImageButton = (props) => {
        if (props.action === 'view') return;
        return (
            <button
                onClick={(e) => {
                    e.preventDefault();
                    props.deleteExistingImage(props.imageIds[props.index], props.file);
                }}
                className="delete">
            </button>
        )
    }

    const DeleteImageButton = (props) => {
        if (props.action === 'view') return;
        return (
            <button
                onClick={(e) => {
                    e.preventDefault();
                    props.onImageRemove(props.index)
                }}
                className="delete">
            </button>
        )
    }

    const NewFiles = (props) => {
        if (props.action === 'view') return;
        return (
            props.imageList.map((image, index) => (
                <div key={index} className="image-item">
                    <a href={image.data_url}>
                        <img src={image.data_url} alt={image.name} />
                    </a>
                    <DeleteImageButton
                        action={props.action}
                        onImageRemove={props.onImageRemove}
                        index={index}
                    />
                </div>
            ))
        )
    }

    return (
        <div className="upload-image-wrapper">
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                onError={onError}
                maxNumber={maxNumber}
                maxFileSize={5242880}
                dataURLKey="data_url"
                acceptType={["jpg", "png", "jpeg"]}
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    <div className="upload-media">
                        <div className="image-items">
                            <ExistingFiles />
                            <NewFiles
                                action={props.action}
                                onImageRemove={onImageRemove}
                                imageList={imageList}
                            />
                        </div>
                        <UploadSpace
                            action={props.action}
                            onImageUpload={onImageUpload}
                            isDragging={isDragging}
                            dragProps={dragProps}
                        />
                    </div>
                )}
            </ImageUploading>
        </div>
    );
}