import React from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import '../assets/scss/containers/donation.scss';
import { URLS } from "../routing/urls";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import farmer from "../assets/img/farmer.svg";
import donor from "../assets/img/donor.svg"
import flag from "../assets/img/ukraine-flag.png"
import i18n from "../i18n";

const FarmerCard = () => {
    const { t } = useTranslation();
    return (
        <div className="card donate-card">
            <span className="text-bigger">{t('iAmFarmer')}</span>
            <img src={farmer} alt="Farmer logo" />
            <span className="text">{t('getAssistance')}</span>
            <Link to={`/${URLS.APPLICATIONS_LIST}`}
                className="btn btn-success"
                onClick={() => {
                    localStorage.setItem('userType', 'farmer');
                }}>{t('continueAsFarmer')}</Link>
        </div>
    )
}

const DonorCard = () => {
    const { t } = useTranslation();
    return (
        <div className="card donate-card">
            <span className="text-bigger">{t('iAmDonor')}</span>
            <img src={donor} alt="Donor logo" />
            <span className="text">{t('viewExistingApp')}</span>
            <Link to={`/${URLS.DONATION}list/`}
                className="btn btn-success"
                onClick={() => {
                    localStorage.setItem('userType', 'donor');
                }}>{t('continueAsDonor')}</Link>
        </div>
    )
}

export const StartPage = () => {
    const { t } = useTranslation();
    localStorage.removeItem('userType');
    return (
        <>
            <Header onlyLogo={true} />
            <main className="bg-image start-page">
                <div className="container">
                    <div className="card p-3 text-center">
                        <div className="page-title">
                            {t('joinFarmerDonor')}
                        </div>
                        <div className="farmer-donor-cards">
                            <FarmerCard />
                            <DonorCard />
                        </div>
                        <div className="text-center py-3">
                            <img src={flag}
                                alt="Ukraine flag"
                                width="160px"
                            />
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}
