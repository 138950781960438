import { AgroReceiptsTable } from '../Tables';
import { CommentModal } from '../../../components/Popup';
import moment from 'moment';
import 'moment/locale/uk';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export const AgroReceiptsBlock = (props) => {
    const { t } = useTranslation();
    const AgroReceiptsData = () => {
        let receipts = [];
        props.moderation?.agrarian_receipts?.objects?.forEach(receipt => {
            receipts = [...receipts, {
                'type': receipt?.type || '-',
                'number': receipt?.id || '-',
                'date': receipt?.cr_date
                    ? moment(receipt.cr_date)?.locale('uk').format('l')
                    : '-',
                'debtor': receipt?.debtors.join(', ') || '-',
                'endDate': receipt?.deadline
                    ? moment(receipt?.deadline).locale('uk').format('l')
                    : '-',
                'key': receipt?.id
            }]
        })
        return receipts;
    }
    return (
        <div className="card">
            <div className="card-header">
                <span>{t('agroReceipts')}</span>
                <CommentModal
                    comments={props.comments}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    block={'receipts'}
                    moderationId={props.moderation.id}
                    setLoading={props.setLoading}
                    moderationView={props.moderationView}
                />
            </div>
            <div className="card-body app-validation-card w-100">
                <div className='block'>
                    <AgroReceiptsTable data={AgroReceiptsData()} />
                </div>
                <div className='form-group'>
                    <input type="checkbox" name="agroReceipts"
                        id="agroReceipts"
                        checked={props.checkboxes.agroReceipts}
                        onChange={(event) => props.handleCheckboxChange(event)} />
                    <label htmlFor='agroReceipts'>
                        {t('noAgroReceipts')}
                    </label>
                </div>
            </div>
        </div>
    )
}