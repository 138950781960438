import { useTranslation } from 'react-i18next';
import { LoadImages } from './LoadImages';


export const Step6 = props => {
    const { t } = useTranslation();
    if (props.currentStep !== 6) {
        return null
    }

    let title = window.innerWidth < 768 ? <div className='page-title'>{props.stepTitle}</div> : '';
    return (
        <>
            {title}
            <div className="form-group">
                <span className="form-section-title">{t('attachments')}</span>
                <LoadImages
                    files={props.form.imagesData}
                    handleFileChange={props.handleFileChange}
                    action='create'
                />
            </div>

            <div className="text grey">{t('attachmentsTips.first')}</div>
            <div className="text grey">{t('attachmentsTips.second')}</div>
        </>
    );
}
