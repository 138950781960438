import Nova from '../../assets/img/nova.svg';
import Address from '../../assets/img/address.svg';
import { Delivery } from './Delivery';
import { useTranslation } from 'react-i18next';

export const Step3 = props => {
    const { t } = useTranslation();
    if (props.currentStep !== 3) {
        return null
    }
    let title = window.innerWidth < 768 ? <div className='page-title'>{props.stepTitle}</div> : '';
    return (
        <>
            {title}
            <div className='deliveries'>
                <div className="form-group w-50">
                    <input
                        className="form-control delivery-input"
                        id="nova"
                        name="delivery_type"
                        type="radio"
                        value='nova'
                        onChange={props.handleDeliveryType}
                        checked={props.form.delivery_type === 'nova'}
                    />
                    <label htmlFor="nova" className="delivery">
                        <img src={Nova} alt={t('novaPoshta')} />
                        <span>{t('novaPoshta')}</span>
                    </label>
                </div>

                <div className="form-group w-50">
                    <input
                        className="form-control delivery-input"
                        id="address"
                        name="delivery_type"
                        type="radio"
                        value='address'
                        onChange={props.handleDeliveryType}
                        checked={props.form.delivery_type === 'address'}
                    />
                    <label htmlFor="address" className="delivery">
                        <img src={Address} alt={t('address')} />
                        <span>{t('address')}</span>
                    </label>
                </div>
            </div>

            <div className="delivery-form">
                <Delivery
                    type={props.form.delivery_type}
                    delivery_details={props.form.delivery_details}
                    handleDeliveryChange={props.handleDeliveryChange}
                />
            </div>
        </>

    );
}
