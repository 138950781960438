import React, { Component } from "react";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import '../assets/scss/form.scss';
import Form from "../components/Form/Form";

export default class CreateApplication extends Component {

    render() {
        return (
            <>
                <Header />
                <main>
                    <div className="container">
                        <Form />
                    </div>
                </main>
                <Footer />
            </>
        )
    }
}
