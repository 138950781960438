export function disableStepOne(validation) {
    if (validation.companyNameValid &&
        validation.activityTypeValid &&
        validation.areaValid &&
        validation.chiefNameValid &&
        validation.chiefPhoneNumberValid) {
        return ''
    }
    else {
        return 'disabled';
    }
};

export function disableStepTwo(validation) {
    return validation.profProductsValid ? '' : 'disabled';
}

export function disableStepThree(validation) {
    return validation.deliveryDetailsValid ? '' : 'disabled';
}

export function disableStepFour(validation) {
    return validation.titleValid ? '' : 'disabled';
}

export function disableStepFive(validation) {
    return validation.descriptionValid ? '' : 'disabled';
}

export function disableStepSeven(validation) {
    if (validation.companyNameValid &&
        validation.activityTypeValid &&
        validation.profProductsValid &&
        validation.deliveryDetailsValid &&
        validation.areaValid &&
        validation.titleValid &&
        validation.descriptionValid) {
        return ''
    } else {
        return 'disabled';
    }
}