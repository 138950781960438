import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Step2 = props => {
    const { t } = useTranslation();
    const [checkboxesCount, setCheckboxesCount] = useState(0);
    if (props.currentStep !== 2) {
        return null
    }
    let title = window.innerWidth < 768 ? <div className='page-title'>{props.stepTitle}</div> : '';

    const increaseCheckboxesCount = (bool) => {
        if (bool) {
            setCheckboxesCount(prev => prev + 1)
        } else {
            setCheckboxesCount(prev => prev - 1)
        }
    }

    return (
        <>
            {title}
            <div className="form-group">
                <span className="form-section-title">{t('profProducts')}</span>
                <div className="checkboxButtons">
                    <input
                        className="form-control"
                        id="seed_and_plant_protection"
                        name="seed_and_plant_protection"
                        type="checkbox"
                        value={props.form.seed_and_plant_protection}
                        checked={props.form.seed_and_plant_protection}
                        onChange={e => {
                            if (checkboxesCount === 3 && e.target.checked) return;
                            props.handleChange.setSeed(e.target.checked);
                            props.handleValidation(e);
                            increaseCheckboxesCount(e.target.checked);
                        }}
                    />
                    <label htmlFor="seed_and_plant_protection">{t('categoryList.seed_and_plant_protection')}</label>

                    <input
                        className="form-control"
                        id="consulting"
                        name="consulting"
                        type="checkbox"
                        value={props.form.consulting}
                        checked={props.form.consulting}
                        onChange={e => {
                            if (checkboxesCount === 3 && e.target.checked) return;
                            props.handleChange.setConsulting(e.target.checked);
                            props.handleValidation(e);
                            increaseCheckboxesCount(e.target.checked);
                        }}
                    />
                    <label htmlFor="consulting">{t('categoryList.consulting')}</label>
                </div>
            </div>

            <div className="form-group">
                <span className="form-section-title">{t('certificates')}</span>
                <div className="checkboxButtons">
                    <input
                        className="form-control"
                        id="epicenter_certificates"
                        name="epicenter_certificates"
                        type="checkbox"
                        value={props.form.epicenter_certificates}
                        checked={props.form.epicenter_certificates}
                        onChange={e => {
                            if (checkboxesCount === 3 && e.target.checked) return;
                            props.handleChange.setEpicenter(e.target.checked);
                            increaseCheckboxesCount(e.target.checked);
                        }}
                    />
                    <label htmlFor="epicenter_certificates">{t('categoryList.epicenter_certificates')}</label>

                    <input
                        className="form-control"
                        id="fuel_coupons"
                        name="fuel_coupons"
                        type="checkbox"
                        value={props.form.fuel_coupons}
                        checked={props.form.fuel_coupons}
                        onChange={e => {
                            if (checkboxesCount === 3 && e.target.checked) return;
                            props.handleChange.setFuel(e.target.checked);
                            increaseCheckboxesCount(e.target.checked);
                        }}
                    />
                    <label htmlFor="fuel_coupons">{t('categoryList.fuel_coupons')}</label>
                </div>
            </div>

            <div className="form-group">
                <span className="form-section-title">{t('finAssist')}</span>
                <div className="checkboxButtons">
                    <input
                        className="form-control"
                        id="money"
                        name="money"
                        type="checkbox"
                        value={props.form.money}
                        checked={props.form.money}
                        onChange={e => {
                            if (checkboxesCount === 3 && e.target.checked) return;
                            props.handleChange.setMoney(e.target.checked);
                            increaseCheckboxesCount(e.target.checked);
                        }}
                    />
                    <label htmlFor="money">{t('categoryList.money')}</label>
                </div>
            </div>
        </>
    );
}
