import { useTranslation } from 'react-i18next';

export const Step4 = props => {
    const { t } = useTranslation();
    if (props.currentStep !== 4) {
        return null
    }
    let title = window.innerWidth < 768 ? <div className='page-title'>{props.stepTitle}</div> : '';
    return (
        <>
            {title}
            <div className="form-group">
                <span className="form-section-title title-md-none">{t('applicationTitle')}</span>
                <input
                    className="form-control"
                    id="title"
                    name="title"
                    type="text"
                    value={props.form.title}
                    onChange={e => {
                        props.handleChange.setApplicationTitle(e.target.value);
                        props.handleValidation(e)
                    }}
                />
            </div>
            <span className="grey-uppercase">{t('examples.title.header')}</span>
            <ul className="examples">
                <li>{t('examples.title.first')}</li>
                <li>{t('examples.title.second')}</li>
                <li>{t('examples.title.third')}</li>
                <li>{t('examples.title.fourth')}</li>
                <li>{t('examples.title.fifth')}</li>
            </ul>
        </>
    );
}
