import React, { useEffect, useState } from "react";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import '../../assets/scss/form.scss';
import axios from 'axios';
import { URLS } from "../../routing/urls";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ConfirmForm } from "../../components/Form/ConfirmForm";
import i18n from "../../i18n";


export const DonationDetailApplicationPage = () => {
    const { t } = useTranslation();
    let [applicationDetail, setApplicationDetail] = useState(false);

    useEffect(() => {
        i18n.changeLanguage('en');
    }, [])

    useEffect(() => {
        getDetailApplication();
    }, [])

    const getDetailApplication = () => {
        let id = window.location.pathname.split('/')[3];
        axios.get(`${URLS.MODERATIONS}detail/public/${id}/`)
            .then(res => {
                let application = res.data.application;
                application.edrpou = application.company.edrpou;
                application.name = application.company.name;
                application.imagesData = application.images;
                application.imagesSrc = parseImagesSrc(application.images);
                application.images = parseImagesIds(application.images);
                setApplicationDetail(application);
            })
            .catch(err => {
                if (err.response.status === 404) {
                    toast.error('Вибачте, деталі заявки відсутні.');
                    return;
                }
                toast.error(t('errorMessage'))
            })
    }


    const parseImagesIds = (images) => {
        let ids = images.map(item => item.id);
        return ids;
    }

    const parseImagesSrc = (images) => {
        let src = images.map(item => item.image);
        return src;
    }

    const renderForm = () => {
        if (!applicationDetail) return;
        return (
            <ConfirmForm form={applicationDetail}
                currentStep={7}
                action="view"
            />
        )
    }

    return (
        <>
            <Header userType={'donor'} />
            <main>
                <div className="container">
                    {renderForm()}
                </div>
                <ToastContainer />
            </main>
            <Footer />
        </>
    )
}