import React, { useState } from "react";
import { LoginForm, ConfirmForm } from "./Forms";

import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import 'react-toastify/dist/ReactToastify.css';

const Auth = () => {
    const { t } = useTranslation();

    const [user_phone_number, setPhoneNumber] = useState('');
    const [success, setStatus] = useState('');
    const [confirm_code, setConfirmCode] = useState('');
    const [phoneButtonValid, setPhoneButtonValid] = useState(false);
    const [codeButtonValid, setCodeButtonValid] = useState(false);

    const handlePhoneChange = (event) => {
        if (event.target.value.length !== 17) {
            setPhoneButtonValid(false)
            return;
        }
        setPhoneNumber(event.target.value);
        setPhoneButtonValid(true)
    }

    const handleCodeChange = (event) => {
        if (event.target.value.length !== 6) {
            setCodeButtonValid(false);
            return;
        }
        setConfirmCode(event.target.value);
        setCodeButtonValid(true);
    }

    return (
        <div className="card">
            <h2>{t('loginHeader')}</h2>
            <LoginForm
                setStatus={setStatus}
                success={success}
                handlePhoneChange={handlePhoneChange}
                user_phone_number={user_phone_number}
                buttonValid={phoneButtonValid}
            />
            <ConfirmForm
                success={success}
                handleCodeChange={handleCodeChange}
                user_phone_number={user_phone_number}
                confirm_code={confirm_code}
                codeValid={codeButtonValid}
            />
            <ToastContainer />
        </div>
    )
}

export default Auth;