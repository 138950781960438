import { CommentModal } from '../../../components/Popup';
import { ExecProceedTable } from '../Tables';
import moment from 'moment';
import 'moment/locale/uk';
import { useTranslation } from 'react-i18next';

export const ExecProceedBlock = (props) => {
    const { t } = useTranslation();
    const ExecProceedData = () => {
        let enforcements = [];
        props.moderation?.enforcement_proceedings?.results?.forEach(enforcement => {
            enforcements = [...enforcements, {
                'id': enforcement?.orderNumber || '-',
                'subject': enforcement?.executor?.name || '-',
                'debt_collector': enforcement?.departmentName || '-',
                'date': moment(enforcement?.dateBegin).locale('uk').format('L') || '-',
                'status': enforcement?.enforcementState || '-',
                'key': enforcement?.orderNumber || '-'
            }]
        })
        return enforcements;
    }
    return (
        <div className="card">
            <div className="card-header">
                <span>{t('proceedings')}</span>
                <CommentModal
                    comments={props.comments}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    block={'enforcements'}
                    moderationId={props.moderation.id}
                    setLoading={props.setLoading}
                    moderationView={props.moderationView}
                />
            </div>
            <div className="card-body app-validation-card w-100">
                <div className='block'>
                    <ExecProceedTable data={ExecProceedData()} />
                </div>
                <div className='form-group'>
                    <input type="checkbox" name="enforcements"
                        id="enforcements"
                        checked={props.checkboxes.enforcements}
                        onChange={(event) => props.handleCheckboxChange(event)} />
                    <label htmlFor='enforcements'>
                        {t('noProcceding')}
                    </label>
                </div>
            </div>
        </div>
    )
}