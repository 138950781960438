import axios from "axios";
import { useParams } from "react-router-dom";
import { axiosWrapper } from "../components/Helper";
import { URLS } from "../routing/urls";

export const GetApplicationId = () => {
    const params = useParams();
    return params.id;
}

export const getFullApplicationInfo = (id, publicInfo = false) => {
    if (publicInfo) {
        return new Promise((resolve, reject) => {
            axios.get(`${URLS.MODERATIONS}detail/public/${id}/`)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err))
        })
    }
    return new Promise((resolve, reject) => {
        axiosWrapper(() => {
            axios.get(`${URLS.MODERATIONS}detail/${id}/`)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err))
        })
    })
}

export const getThreeClosestApplicationsIds = (moderationId, category) => {
    return new Promise((resolve, reject) => {
        axios.get(`${URLS.APPLICATIONS_LIST}closest/${moderationId}/${category}/`)
            .then(res => {
                resolve(res);
            })
            .catch(err => reject(err))
    })
}

export const getApplicationById = (id) => {
    return new Promise((resolve, reject) => {
        axiosWrapper(() => {
            axios.get(`/${URLS.APPLICATION_DETAILS}${id}/`)
                .then(res => {
                    resolve(res);
                })
                .catch(err => reject(err))
        })
    })
}