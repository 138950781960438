import { LandBankTable } from '../Tables';
import { Map } from '../../../components/Map';
import { CommentModal } from '../../../components/Popup';
import { t } from 'i18next';
import { URLS } from '../../../routing/urls';
import axios from 'axios';
import { axiosWrapper } from '../../../components/Helper';
import { toast } from 'react-toastify';

export const LandBankBlock = (props) => {

    const LandBankData = () => {
        let places = [];
        props.moderation?.places?.forEach(place => {
            places = [...places, {
                'koatuu': place?.koatuu || '-',
                'settlement': place?.settlement || '-',
                'area': place?.area || '-',
                'count': place?.count
            }]
        })
        return places;
    }

    const getFamiDataFromURL = () => {
        const url = `/${URLS.FAMI}${props.moderation.id}/xlsx/`;
        axiosWrapper(() => {
            axios.get(url, {
                method: 'GET',
                responseType: 'blob',
            })
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `drrp.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => {
                    toast.error(t('errorMessage'));
                })
        })
    }

    const handleRadioInput = (event) => {
        props.setTerritoryType(event.target.value);
    }

    const CheckInfo = () => {
        const numbers = () => {
            let fami_request = props.moderation.fami_request;
            if (!fami_request?.request_numbers?.length) return '-';
            return fami_request.request_numbers.map(number => <div>{number}</div>)
        }
        return (
            <>
                <div className="sub-block">
                    <span className="header">
                        <span className="title">{t('checkDetails')}</span>
                        <a onClick={getFamiDataFromURL} 
                            className="download">{t('download')}</a>
                    </span>
                    <span className="body">
                        {numbers()}
                    </span>
                </div>
                <div className='form-group sub-block'>
                    <input type="checkbox" name="checkData"
                        id="checkData"
                        checked={props.checkboxes.checkData}
                        onChange={(event) => props.handleCheckboxChange(event)} />
                    <label htmlFor='checkData'>{t('yes')}</label>
                </div>

            </>
        )
    }

    const DrrpStatus = (props) => {
        const statuses = {
            'not_created': 'Запит на отримання даних не створений',
            'in_process': 'Запит на отримання даних у процесі',
            'succeed': 'Дані успішно отримано',
            'error': 'Виникла помилка при отриманні даних'
        }

        return (
            <div>{statuses[props.drrp?.status]}</div>
        )
    }

    return (
        <div className="card">
            <div className="card-header">
                <span>{t('landBank')}</span>
                <CommentModal
                    comments={props.comments}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    block={'land_bank'}
                    moderationId={props.moderation.id}
                    setLoading={props.setLoading}
                    moderationView={props.moderationView}
                />
            </div>
            <div className="card-body app-validation-card w-100">
                <DrrpStatus drrp={props.moderation.drrp} />
                <Map deepState={true} pointsData={props.moderation.drrp?.geojson} />
                <div className='block'>
                    <div className='sub-block'>
                        <span>{t('landPlots')}, {t('pcs')}</span>
                        <span>{props.moderation.drrp?.numbers_count || "-"}</span>
                    </div>
                    <div className='form-group sub-block'>
                        <input type="checkbox" name="lotCount"
                            id="lotCount"
                            checked={props.checkboxes.lotCount}
                            onChange={(event) => props.handleCheckboxChange(event)} />
                        <label htmlFor='lotCount'>{t('yes')}</label>
                    </div>
                </div>
                <div className='block'>
                    <div className='sub-block'>
                        <span>{t('area')}, {t('ha')}</span>
                        <span>{props.moderation.drrp?.area || "-"}</span>
                    </div>
                    <div className='form-group sub-block'>
                        <input type="checkbox" name="lotArea"
                            id="lotArea"
                            checked={props.checkboxes.lotArea}
                            onChange={(event) => props.handleCheckboxChange(event)} />
                        <label htmlFor='lotArea'>{t('yes')}</label>
                    </div>
                </div>
                <div className='block'>
                    <div className='sub-block'>
                        <span>{t('landInUse')}</span>
                        <span>{props.moderation.application.area || '-'}</span>
                    </div>
                    <div className='form-group sub-block'>
                        <input type="checkbox" name="inUse"
                            id="inUse"
                            checked={props.checkboxes.inUse}
                            onChange={(event) => props.handleCheckboxChange(event)} />
                        <label htmlFor='inUse'>{t('yes')}</label>
                    </div>
                </div>
                <div className='block'>
                    <div className='sub-block'>
                        <span>{t('typeOfTerritory')}</span>
                        <span>
                            <div className='form-group'>
                                <input type="radio" name="territory_type"
                                    id="occupied"
                                    value='occupied'
                                    checked={props.territoryType === 'occupied'}
                                    onChange={(event) => handleRadioInput(event)} />
                                <label htmlFor='occupied'>{t('occupied')}</label>
                            </div>
                            <div className='form-group'>
                                <input type="radio" name="territory_type"
                                    id="deoccupied"
                                    value='deoccupied'
                                    checked={props.territoryType === 'deoccupied'}
                                    onChange={(event) => handleRadioInput(event)} />
                                <label htmlFor='deoccupied'>{t('deoccupied')}</label>
                            </div>
                            <div className='form-group'>
                                <input type="radio" name="territory_type"
                                    id="active_hostilities"
                                    value='active_hostilities'
                                    checked={props.territoryType === 'active_hostilities'}
                                    onChange={(event) => handleRadioInput(event)} />
                                <label htmlFor='active_hostilities'>{t('activeZone')}</label>
                            </div>
                        </span>
                    </div>
                    <div className='form-group sub-block'>
                        <input type="checkbox" name="territoryType"
                            id="territoryType"
                            checked={props.checkboxes.territoryType}
                            onChange={(event) => props.handleCheckboxChange(event)} />
                        <label htmlFor='territoryType'>{t('yes')}</label>
                    </div>
                </div>
                <div className='block'>
                    <LandBankTable data={LandBankData()} />
                </div>
                <div className='block check-info'>
                    <CheckInfo />
                </div>
            </div>
        </div>
    )
}