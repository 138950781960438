import React, { Component } from "react";

export default class Error extends Component {

    render() {
        return (
            <>
                <h1 className="text-center">Вибачте, виникла помилка. </h1>
                <h3 className="text-center">Попробуйте перезавантажити сторінку.</h3>
            </>
        )
    }
}
