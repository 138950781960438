import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
    getFullApplicationInfo,
    getThreeClosestApplicationsIds
} from "../../services/Application.service";
import emptyImage from '../../assets/img/empty-image.png'
import { getRandomDonationUrl } from "../../services/Donation.service";
import { TailSpin } from 'react-loader-spinner';
import { URLS } from "../../routing/urls";
import moment from 'moment';
import { Link } from "react-router-dom";
import exclamationMark from '../../assets/img/exclamation-mark.svg';
import { getLandInUseArea } from "../../components/Helper";
import i18n from "../../i18n";

export function DonateModal(props) {
    const [show, setShow] = useState(false);
    const [randomApplications, setRandomApplications] = useState([]);
    const [randomIds, setRandomIds] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage('en');
    }, [])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDonateBtnClick = () => {
        handleShow();
        let closestApplications = getThreeClosestApplicationsIds(props.moderationId, props.checkedCategory);
        closestApplications.then(res => {
            let ids = res.data.map(obj => obj.moderation_id);
            setRandomIds(ids);
            Promise.all(ids.map(id => getFullApplicationInfo(id, true)))
                .then(responses => {
                    setRandomApplications(() => {
                        return responses.map(res => res.data)
                    })
                })
                .catch(error => {
                    toast.error(t('errorMessage'))
                    console.error(error);
                });
        })
    }

    const Content = () => {
        if (!randomApplications.length) return;
        return (
            <>
                {randomApplications.map((moderation, index) => {
                    return <ApplicationPreview key={index}
                        moderation={moderation} />
                })}
            </>
        )
    }

    const ApplicationPreview = (props) => {
        const Image = () => {
            if (!props.moderation.application?.images.length) {
                return <img
                    src={emptyImage} alt="Application Attachments" />
            }
            return <img
                src={props.moderation.application.images[0]?.image}
                alt="Application Attachments" />
        }

        const MomentDate = () => {
            if (props.moderation?.validated_at) {
                return moment(props.moderation?.validated_at).format('L');
            }
            if (props.moderation.created_at) {
                return moment(props.moderation.created_at).format('L');
            }
            return '-'
        }

        let application = props.moderation.application;
        let area = getLandInUseArea(application?.drrp?.area, application?.area);

        return (
            <Link to={`/${URLS.DONATION}${props.moderation.id}/`}
                className="card-hover text-center"
                target="_blank"
                rel="noreferrer">
                <div className="card donate-card">
                    <div>
                        <div className="text-bigger cut-text">
                            {props.moderation.translation?.en?.headline ||
                                props.moderation.application?.title}
                        </div>
                        <div className="sub-info justify-content-center">
                            <span className="application-id">
                                ID: {props.moderation.application?.id.toString().padStart(4, '0')}
                            </span>
                            <span className="application-date">
                                <MomentDate />
                            </span>
                        </div>
                        <div className="area grey text-uppercase">
                            {area} {t('ha')} / {(area * 2.471).toFixed(2)} {t('acres')}
                        </div>
                    </div>
                    <Image />
                </div>
            </Link>
        )
    }

    const LoadingApplications = () => {
        return (
            <>
                {[1, 2, 3].map((index) => {
                    return <div className="card donate-card justify-content-center"
                        key={index}>
                        <div className="text-bigger text-center">{t('dataLoading')}</div>
                        <div className="d-flex justify-content-center">
                            <TailSpin color="grey" ariaLabel="loading-indicator" />
                        </div>
                    </div>
                })}
            </>
        )
    }

    return (
        <>
            <div className={`btn btn-success center ${props.btnClass}`}
                onClick={handleDonateBtnClick}>{t('donate')}
            </div>
            <Modal show={show}
                onHide={handleClose}
                centered
                className='donate-modal'
                dialogClassName="modal-xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="warning warning-green text-center">
                            <img src={exclamationMark} alt='exclamation mark' />
                            <span>{t('attention')}</span>
                        </div>
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body className="">
                    <span className="text text-center">{t('USArules')}</span>
                    <div className="row-gap justify-content-center">
                        {randomApplications.length !== 0 ? <Content /> : <LoadingApplications />}
                    </div>
                    <div className="text-center">
                        <button className="btn btn-success"
                            onClick={() => {
                                getRandomDonationUrl(randomIds, props.checkedCategory)
                                    .then(res => {
                                        if (!res.data.donation_url) {
                                            toast.error(t('errorEmptyDonationUrl'))
                                            return;
                                        }
                                        window.open(res.data.donation_url)
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        toast.error(t('errorMessage'))
                                    })
                            }}>{t('donate')}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
