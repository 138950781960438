import { getCookie } from "./Cookie";
import axios from 'axios';

export function getHeaders() {
    let token = getCookie("access_token");
    if (!token) return;
    return `Bearer ${token}`;
}

export function setHeaders() {
    axios.defaults.headers.common['Authorization'] = getHeaders();
}