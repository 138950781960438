import { removeCookie } from "../components/Cookie";

export const Logout = () => {
    removeCookie('access_token');
    removeCookie('refresh_token');
    removeCookie('user_id');
    removeUser();
}

export const getUser = () => {
    return localStorage.getItem('auth');
}

export const setUser = () => {
    localStorage.setItem('auth', true)
}

export const removeUser = () => {
    localStorage.removeItem('auth');
}