import { useTranslation } from 'react-i18next';
import { CommentModal } from '../../../components/Popup';
import { DebtorsTable } from '../Tables';

export const DebtorsBlock = (props) => {
    const { t } = useTranslation();
    const DebtorsData = () => {
        let debtors = [];
        props.moderation?.debtors?.forEach((debtor, index) => {
            debtors = [...debtors, {
                'role': debtor?.role || '-',
                'name': `${debtor?.first_name} ${debtor?.last_name} ${debtor?.middle_name}` || '-',
                'info': debtor?.is_debtor ? 'Є боржником' : 'Відсутні' || '-',
                'key': index
            }]
        })
        return debtors;
    }
    return (
        <div className="card">
            <div className="card-header">
                <span>{t('debtorsRegistry')}</span>
                <CommentModal 
                    comments={props.comments}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    block={'debtors'}
                    moderationId={props.moderation.id}
                    setLoading={props.setLoading}
                    moderationView={props.moderationView}
                />
            </div>
            <div className="card-body app-validation-card w-100">
                <div className='block'>
                    <DebtorsTable data={DebtorsData()} />
                </div>
                <div className='form-group'>
                    <input type="checkbox" name="debtors"
                        id="debtors"
                        checked={props.checkboxes.debtors}
                        onChange={(event) => props.handleCheckboxChange(event)} />
                    <label htmlFor='debtors'>
                        {t('noDebts')}
                    </label>
                </div>
            </div>
        </div>
    )
}