import { BudgetTable, CompanyInfoTable } from "../Tables";
import { useTranslation } from 'react-i18next';
import React, { useEffect } from "react";
import Cleave from 'cleave.js/react';
import { getLandInUseArea } from "../../../components/Helper";

export const BudgetBlock = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        updateBudget();
    }, [props.assignedInputs])

    useEffect(() => {
        props.setDonationUrls(props.donationInputs)
    }, [props.donationInputs])

    const updateBudget = () => {
        props.setBudget(prevState => ({
            ...prevState,
            'assigned': props.assignedInputs
        }))
    }

    const tableData = () => {
        if (!props.moderation) return [];
        let application = props.moderation.application;
        let inputs = {
            seeds: <div className="form-group">
                <input type="text"
                    name="seeds"
                    className="form-control"
                    disabled={!application.seed_and_plant_protection}
                    onChange={(event) => {
                        event.preventDefault();
                        const enteredValue = Number(event.target.value);
                        const maxSeedsLimit = props.moderation.budget.max_limits.seeds_max;
                        const maxBudget = props.moderation.budget.max_budget;

                        // if (enteredValue <= maxSeedsLimit && enteredValue <= maxBudget) {
                            props.setAssignedInputs(prevState => ({
                                ...prevState,
                                'seeds': event.target.value,
                                'consulting': props.assignedInputs.consulting,
                                'epicenter': props.assignedInputs.epicenter,
                                'fuel': props.assignedInputs.fuel,
                                'cash_support': props.assignedInputs.cash_support
                            }))
                        // }
                    }}
                    value={props.assignedInputs.seeds || ''}
                />
            </div>,
            consulting: <div className="form-group">
                <input type="text"
                    name="consulting"
                    className="form-control"
                    disabled={!application.consulting}
                    onChange={(event) => {
                        event.preventDefault();
                        const enteredValue = Number(event.target.value);
                        const maxConsultingLimit = props.moderation.budget.max_limits.consulting_max;
                        const maxBudget = props.moderation.budget.max_budget;

                        // if (enteredValue <= maxConsultingLimit && enteredValue <= maxBudget) {
                            props.setAssignedInputs((prevState) => ({
                                ...prevState,
                                seeds: props.assignedInputs.seeds,
                                consulting: event.target.value,
                                epicenter: props.assignedInputs.epicenter,
                                fuel: props.assignedInputs.fuel,
                                cash_support: props.assignedInputs.cash_support
                            }));
                        // }
                    }}
                    value={props.assignedInputs.consulting || ''}
                />
            </div>,
            epicenter: <div className="form-group">
                <input type="text"
                    name="epicenter"
                    className="form-control"
                    disabled={!application.epicenter_certificates}
                    onChange={(event) => {
                        event.preventDefault();
                        const enteredValue = Number(event.target.value);
                        const maxEpicenterLimit = props.moderation.budget.max_limits.epicenter_max;
                        const maxBudget = props.moderation.budget.max_budget;

                        // if (enteredValue <= maxEpicenterLimit && enteredValue <= maxBudget) {
                            props.setAssignedInputs(prevState => ({
                                ...prevState,
                                'seeds': props.assignedInputs.seeds,
                                'consulting': props.assignedInputs.consulting,
                                'epicenter': event.target.value,
                                'fuel': props.assignedInputs.fuel,
                                'cash_support': props.assignedInputs.cash_support
                            }))
                        // }
                    }}
                    value={props.assignedInputs.epicenter || ''}
                />
            </div>,
            fuel: <div className="form-group">
                <input type="text"
                    name="fuel"
                    className="form-control"
                    disabled={!application.fuel_coupons}
                    onChange={(event) => {
                        event.preventDefault();
                        const enteredValue = Number(event.target.value);
                        const maxFuelLimit = props.moderation.budget.max_limits.fuel_max;
                        const maxBudget = props.moderation.budget.max_budget;

                        // if (enteredValue <= maxFuelLimit && enteredValue <= maxBudget) {
                            props.setAssignedInputs(prevState => ({
                                ...prevState,
                                'seeds': props.assignedInputs.seeds,
                                'consulting': props.assignedInputs.consulting,
                                'epicenter': props.assignedInputs.epicenter,
                                'fuel': event.target.value,
                                'cash_support': props.assignedInputs.cash_support
                            }))
                        // }
                    }}
                    value={props.assignedInputs.fuel || ''}
                />
            </div>,
            cash_support: <div className="form-group">
                <input type="text"
                    name="finance"
                    className="form-control"
                    disabled={!application.money}
                    onChange={(event) => {
                        event.preventDefault();
                        const enteredValue = Number(event.target.value);
                        const maxCashLimit = props.moderation.budget.max_limits.cash_support_max;
                        const maxBudget = props.moderation.budget.max_budget;

                        // if (enteredValue <= maxCashLimit && enteredValue <= maxBudget) {
                            props.setAssignedInputs(prevState => ({
                                ...prevState,
                                'seeds': props.assignedInputs.seeds,
                                'consulting': props.assignedInputs.consulting,
                                'epicenter': props.assignedInputs.epicenter,
                                'fuel': props.assignedInputs.fuel,
                                'cash_support': event.target.value
                            }))
                        // }
                    }}
                    value={props.assignedInputs.cash_support || ''}
                    options={{
                        numericOnly: true,
                    }}
                />
            </div>
        }
        let checkboxes = {
            seeds: <>
                <div className="form-group">
                    <Cleave
                        className="form-control"
                        id="seed_and_plant_protection"
                        name="seed_and_plant_protection"
                        type="checkbox"
                        value={application.seed_and_plant_protection || 0}
                        checked={application.seed_and_plant_protection}
                        disabled
                        options={{
                            numericOnly: true,
                        }}
                    />
                    <label htmlFor="seed_and_plant_protection">
                        {t('categoryList.seed_and_plant_protection')}
                    </label>
                </div>
            </>,
            consulting: <div className="form-group">
                <Cleave
                    className="form-control"
                    id="consulting"
                    name="consulting"
                    type="checkbox"
                    value={application.consulting || 0}
                    checked={application.consulting}
                    disabled
                    options={{
                        numericOnly: true,
                    }}
                />
                <label htmlFor="consulting">
                    {t('categoryList.consulting')}
                </label>
            </div>,
            epicenter: <>
                <div className="form-group">
                    <Cleave
                        className="form-control"
                        id="epicenter_certificates"
                        name="epicenter_certificates"
                        type="checkbox"
                        value={application.epicenter_certificates}
                        checked={application.epicenter_certificates}
                        disabled
                        options={{
                            numericOnly: true,
                        }}
                    />
                    <label htmlFor="epicenter_certificates">
                        {t('categoryList.epicenter_certificates')}
                    </label>
                </div>
            </>,
            fuel: <div className="form-group">
                <Cleave
                    className="form-control"
                    id="fuel_coupons"
                    name="fuel_coupons"
                    type="checkbox"
                    value={application.fuel_coupons}
                    checked={application.fuel_coupons}
                    disabled
                    options={{
                        numericOnly: true,
                    }}
                />
                <label htmlFor="fuel_coupons">{t('categoryList.fuel_coupons')}</label>
            </div>,
            cash_support: <>
                <div className="form-group">
                    <Cleave
                        className="form-control"
                        id="money"
                        name="money"
                        type="checkbox"
                        value={application.money}
                        checked={application.money}
                        disabled
                        options={{
                            numericOnly: true,
                        }}
                    />
                    <label htmlFor="money">{t('categoryList.money')}</label>
                </div>
            </>
        }

        return [
            {
                categories: <span className="form-section-title">{t('profProducts')}</span>,
                key: '0'
            }, {
                categories: checkboxes.seeds,
                minBudget: props.budget.min_limits.seeds_min || 0,
                maxBudget: props.budget.max_limits.seeds_max || 0,
                recBudget: props.budget.recommended.seeds_recommended || 0,
                applyBudget: inputs.seeds,
                key: '1'
            }, {
                categories: t('donationUrl'),
                minBudget: <div className="form-group p-0">
                    <input type="text"
                        name='seeds'
                        className={`form-control 
                            ${application.seed_and_plant_protection ? '' : 'd-none'}`
                        }
                        value={props.donationInputs.seeds}
                        onChange={(e) => {
                            props.setDonationInputs(prevState => ({
                                ...prevState,
                                'seeds': e.target.value,
                                'consulting': props.donationInputs.consulting,
                                'epicenter': props.donationInputs.epicenter,
                                'fuel': props.donationInputs.fuel,
                                'cash_support': props.donationInputs.cash_support
                            }))
                        }}
                    />
                </div>,
                key: '2'
            },
            {
                categories: checkboxes.consulting,
                minBudget: props.budget.min_limits.consulting_min || 0,
                maxBudget: props.budget.max_limits.consulting_max || 0,
                recBudget: props.budget.recommended.consulting_recommended || 0,
                applyBudget: inputs.consulting,
                key: '3'
            }, {
                categories: t('donationUrl'),
                minBudget: <div className="form-group p-0">
                    <input type="text"
                        name='consulting'
                        className={`form-control 
                            ${application.consulting ? '' : 'd-none'}`
                        }
                        value={props.donationInputs.consulting}
                        onChange={(e) => {
                            props.setDonationInputs(prevState => ({
                                ...prevState,
                                'seeds': props.donationInputs.seeds,
                                'consulting': e.target.value,
                                'epicenter': props.donationInputs.epicenter,
                                'fuel': props.donationInputs.fuel,
                                'cash_support': props.donationInputs.cash_support
                            }))
                        }}
                    />
                </div>,
                key: '4'
            }, {
                categories: <span className="form-section-title">{t('certificates')}</span>,
                key: '5'
            }, {
                categories: checkboxes.epicenter,
                minBudget: props.budget.min_limits.epicenter_min || 0,
                maxBudget: props.budget.max_limits.epicenter_max || 0,
                recBudget: props.budget.recommended.epicenter_recommended || 0,
                applyBudget: inputs.epicenter,
                key: '6'
            }, {
                categories: t('donationUrl'),
                minBudget: <div className="form-group p-0">
                    <input type="text"
                        name='epicenter'
                        className={`form-control 
                            ${application.epicenter_certificates ? '' : 'd-none'}`
                        }
                        value={props.donationInputs.epicenter}
                        onChange={(e) => {
                            props.setDonationInputs(prevState => ({
                                ...prevState,
                                'seeds': props.donationInputs.seeds,
                                'consulting': props.donationInputs.consulting,
                                'epicenter': e.target.value,
                                'fuel': props.donationInputs.fuel,
                                'cash_support': props.donationInputs.cash_support
                            }))
                        }}
                    />
                </div>,
                key: '7'
            }, {
                categories: checkboxes.fuel,
                minBudget: props.budget.min_limits.fuel_min || 0,
                maxBudget: props.budget.max_limits.fuel_max || 0,
                recBudget: props.budget.recommended.fuel_recommended || 0,
                applyBudget: inputs.fuel,
                key: '8'
            }, {
                categories: t('donationUrl'),
                minBudget: <div className="form-group p-0">
                    <input type="text"
                        name='fuel'
                        className={`form-control 
                            ${application.fuel_coupons ? '' : 'd-none'}`
                        }
                        value={props.donationInputs.fuel}
                        onChange={(e) => {
                            props.setDonationInputs(prevState => ({
                                ...prevState,
                                'seeds': props.donationInputs.seeds,
                                'consulting': props.donationInputs.consulting,
                                'epicenter': props.donationInputs.epicenter,
                                'fuel': e.target.value,
                                'cash_support': props.donationInputs.cash_support
                            }))
                        }}
                    />
                </div>,
                key: '9'
            }, {
                categories: <span className="form-section-title">{t('finAssist')}</span>,
                key: '10'
            }, {
                categories: checkboxes.cash_support,
                minBudget: props.budget.min_limits.cash_support_min || 0,
                maxBudget: props.budget.max_limits.cash_support_max || 0,
                recBudget: props.budget.recommended.cash_support_recommended || 0,
                applyBudget: inputs.cash_support,
                key: '11'
            }, {
                categories: t('donationUrl'),
                minBudget: <div className="form-group p-0">
                    <input type="text"
                        name='cash_support'
                        className={`form-control 
                            ${application.money ? '' : 'd-none'}`
                        }
                        value={props.donationInputs.cash_support}
                        onChange={(e) => {
                            props.setDonationInputs(prevState => ({
                                ...prevState,
                                'seeds': props.donationInputs.seeds,
                                'consulting': props.donationInputs.consulting,
                                'epicenter': props.donationInputs.epicenter,
                                'fuel': props.donationInputs.fuel,
                                'cash_support': e.target.value,
                            }))
                        }}
                    />
                </div>,
                key: '12'
            }
        ];
    }

    const sumRecommended = () => {
        let rec = props.budget.recommended;
        return Number(rec.seeds_recommended) +
            Number(rec.consulting_recommended) +
            Number(rec.epicenter_recommended) +
            Number(rec.fuel_recommended) +
            Number(rec.cash_support_recommended);
    }

    const sumAssigned = () => {
        let rec = props.assignedInputs;
        return Number(rec.seeds) +
            Number(rec.consulting) +
            Number(rec.epicenter) +
            Number(rec.fuel) +
            Number(rec.cash_support);
    }

    const CompanyInfoData = () => {
        if (!props.moderation) return [];
        const application = props.moderation.application;
        return [{
            area: getLandInUseArea(application.drrp?.area, application.area),
            activity: t(props.moderation.application.activity_type),
            sum1: sumRecommended(),
            sum2: sumAssigned(),
            key: '4'
        }];
    }
    return (
        <div className="card">
            <div className="card-body pp-validation-card w-100">
                <div className="block">
                    <CompanyInfoTable data={CompanyInfoData()} />
                    <BudgetTable data={tableData()} />
                </div>
            </div>
        </div>
    )
}