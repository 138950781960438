import axios from "axios"
import { URLS } from "../routing/urls"

export const getRandomDonationUrl = (ids, category) => {
    const shortCategoryName = {
        epicenter_certificates: 'epicenter',
        fuel_coupons: 'fuel',
        money: 'cash_support',
        seed_and_plant_protection: 'seeds',
        consulting: 'consulting'
    }
    return new Promise((resolve, reject) => {
        axios.post(`${URLS.APPLICATIONS_LIST}donation-url/`, {
            moderation_ids: ids,
            category: shortCategoryName[category]
        })
            .then(res => {
                resolve(res);
            })
            .catch(err => reject(err))
    })
}