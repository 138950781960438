import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import '../assets/scss/footer.scss';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer>
            <div className="footer">
                <span>© {new Date().getFullYear()}. FARMERHOOD</span>
                <span className="policy">
                    <a href="https://farmerhood.org/index.php/uk/terms-and-conditions-ua">
                        {t('policy')}
                    </a>
                    <a href="https://farmerhood.org/index.php/uk/terms-and-conditions-ua">
                        {t('termOfUse')}
                    </a>
                </span>
            </div>
        </footer>
    )
}

export default Footer;
