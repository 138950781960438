import React, { useState } from 'react';
import { TranslateBlock } from "../blocks/TranslateBlock";
import { ButtonsPanel } from '../Buttons';
import { useTranslation } from 'react-i18next';

export const TranslateTab = (props) => {
    const { t } = useTranslation();
    let initialTranslations = {
        en: {
            headline: '',
            description: '',
        },
        de: {
            headline: '',
            description: '',
        },
        fr: {
            headline: '',
            description: '',
        }
    }
    const [translations, setTranslations] = useState(props.moderation.translation || initialTranslations);

    return (
        <>
            <form>
                <TranslateBlock moderation={props.moderation}
                    setTranslations={setTranslations}
                    translations={translations}
                />
            </form>
            {!props.moderation?.application?.accepted &&
                <ButtonsPanel
                    score={Number(sessionStorage.getItem('score'))}
                    moderation={props.moderation}
                    comments={props.comments}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    currentTab='translationTab'
                    translations={translations}
                    lastModeration={props.lastModeration.id === props.currentModerationId}
                    buttonName={`${t('saveChanges')} ${t('ofBlock')} "${t('translations')}"`}
                    moderationView={props.moderationView}
                />
            }
        </>
    )
}