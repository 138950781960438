import { CommentModal } from '../../../components/Popup';
import { useTranslation } from 'react-i18next';

export const SanctionBlock = (props) => {
    const { t } = useTranslation();
    return (
        <div className="card">
            <div className="card-header">
                <span>{t('sanctions')}</span>
                <CommentModal
                    comments={props.comments}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    block={'sanctions'}
                    moderationId={props.moderation.id}
                    setLoading={props.setLoading}
                    moderationView={props.moderationView}
                />
            </div>
            <div className="card-body app-validation-card w-100">
                <div className='block'>
                </div>
                <div className='form-group'>
                    <input type="checkbox" name="sanctions"
                        id="sanctions"
                        checked={props.checkboxes.sanctions}
                        onChange={(event) => props.handleCheckboxChange(event)} />
                    <label htmlFor='sanctions'>
                        {t('noSanctions')}
                    </label>
                </div>
            </div>
        </div>
    )
}