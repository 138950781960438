import React, { useEffect, useState } from "react";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import '../assets/scss/form.scss';
import axios from 'axios';
import { URLS } from "../routing/urls";
import { axiosWrapper } from "../components/Helper";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ConfirmForm } from "../components/Form/ConfirmForm";


export const ViewApplicationPage = () => {
    const { t } = useTranslation();
    let [applicationDetail, setApplicationDetail] = useState(false);

    useEffect(() => {
        getDetailApplication();
    }, [])

    const getDetailApplication = () => {
        let id = window.location.pathname.split('/')[3];
        axiosWrapper(() => {
            axios.get(`${URLS.APPLICATIONS_LIST}detail/${id}/`)
                .then(res => {
                    res.data.edrpou = res.data.company.edrpou;
                    res.data.name = res.data.company.name;
                    res.data.imagesData = res.data.images;
                    res.data.imagesSrc = parseImagesSrc(res.data.images);
                    res.data.images = parseImagesIds(res.data.images);
                    setApplicationDetail(res.data);
                })
                .catch(err => {
                    if (err.response.status === 404) {
                        toast.error('Вибачте, деталі заявки відсутні.');
                        return;
                    }
                    toast.error(t('errorMessage'))
                })
        })
    }


    const parseImagesIds = (images) => {
        let ids = images.map(item => item.id);
        return ids;
    }

    const parseImagesSrc = (images) => {
        let src = images.map(item => item.image);
        return src;
    }

    const renderForm = () => {
        if (applicationDetail !== false) {
            return (
                <ConfirmForm form={applicationDetail}
                    currentStep={7}
                    action="view"
                />
            )
        }
    }

    return (
        <>
            <Header />
            <main>
                <div className="container">
                    {renderForm()}
                </div>
                <ToastContainer />
            </main>
            <Footer />
        </>
    )
}