import { Collapse } from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Category = (props) => {
    const { t } = useTranslation();
    const isDesktop = () => {
        return window.innerWidth < 768 ? false : true;
    }

    const [open, setOpen] = useState(isDesktop());
    const [title, setTitle] = useState(t('showCategory'));

    const category = {
        epicenter_certificates: t('categoryList.epicenter_certificates'),
        fuel_coupons: t('categoryList.fuel_coupons'),
        money: t('categoryList.money'),
        seed_and_plant_protection: t('categoryList.seed_and_plant_protection'),
        consulting: t('categoryList.consulting')
    }

    const applicationId = props.application.id.toString().padStart(4, '0');

    return (
        <>
            <div className="collapse-images-header"
                onClick={() => {
                    setOpen(!open);
                    setTitle(() => {
                        return open ? t('showCategory') : t('hideCategory')
                    });
                }}
                aria-controls="collapse-category"
                aria-expanded={open}>
                {title}
            </div>
            <Collapse in={open}>
                <div className="collapse-category">
                    <ul className="list">
                        <li
                            style={{ display: props.application.seed_and_plant_protection ? 'flex' : 'none' }}>
                            {category.seed_and_plant_protection}
                            <div className="application-id">
                                ID: {applicationId}.01
                            </div>
                        </li>
                        <li
                            style={{ display: props.application.consulting ? 'flex' : 'none' }}>
                            {category.consulting}
                            <div className="application-id">
                                ID: {applicationId}.02
                            </div>
                        </li>
                        <li
                            style={{ display: props.application.fuel_coupons ? 'flex' : 'none' }}>
                            {category.fuel_coupons}
                            <div className="application-id">
                                ID: {applicationId}.03
                            </div>
                        </li>
                        <li
                            style={{ display: props.application.epicenter_certificates ? 'flex' : 'none' }}>
                            {category.epicenter_certificates}
                            <div className="application-id">
                                ID: {applicationId}.04
                            </div>
                        </li>
                        <li
                            style={{ display: props.application.money ? 'flex' : 'none' }}>
                            {category.money}
                            <div className="application-id">
                                ID: {applicationId}.05
                            </div>
                        </li>
                    </ul>
                </div>
            </Collapse>
        </>
    );
}