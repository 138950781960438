import React, { useState, useEffect } from "react";
import { Step1 } from "./StepOne";
import { Step2 } from "./StepTwo";
import { Step3 } from "./StepThree";
import { Step4 } from "./StepFour";
import { Step5 } from "./StepFive";
import { Step6 } from "./StepSix";
import axios from 'axios';
import { URLS } from "../../routing/urls";
import { ConfirmForm } from "./ConfirmForm";
import { Link } from 'react-router-dom';
import {
    disableStepOne,
    disableStepTwo,
    disableStepThree,
    disableStepFour,
    disableStepFive,
    disableStepSeven
} from "./Validations";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { axiosWrapper } from "../Helper";
import { useTranslation } from 'react-i18next';
import { getCookie } from "../Cookie";
import { useNavigate } from "react-router-dom";

const Form = (props) => {
    const { t } = useTranslation();

    let [currentStep, setCurrentStep] = useState(1);
    const [user, setUser] = useState(getCookie('user_id'));
    const [company, setCompany] = useState('');
    const [chief_name, setChiefName] = useState('');
    const [chief_phone_number, setChiefPhoneNumber] = useState('');
    const [edrpou, setEdrpou] = useState('');
    const [name, setName] = useState('');
    const [area, setArea] = useState('');
    const [activity_type, setActivityType] = useState('');

    const [seed_and_plant_protection, setSeed] = useState(false);
    const [consulting, setConsulting] = useState(false);
    const [epicenter_certificates, setEpicenter] = useState(false);
    const [fuel_coupons, setFuel] = useState(false);
    const [money, setMoney] = useState(false);

    const [delivery_type, setDeliveryType] = useState('');
    const [delivery_details, setDeliveryDetails] = useState({});
    const [title, setApplicationTitle] = useState('');
    const [description, setDescription] = useState('');
    let [images, setImagesId] = useState([]);
    let [imagesData, setImagesData] = useState('');
    let [imagesSrc, setImagesSrc] = useState([]);
    const [status, setStatus] = useState('');

    const [companyNameValid, setCompanyNameValid] = useState(false);
    const [chiefNameValid, setChiefNameValid] = useState(false);
    const [chiefPhoneNumberValid, setChiefPhoneNumberValid] = useState(false);
    const [activityTypeValid, setActivityTypeValid] = useState(false);
    const [areaValid, setAreaValid] = useState(false);
    const [seedValid, setSeedValid] = useState(false);
    const [consultingValid, setConsultingValid] = useState(false);
    const [profProductsValid, setProfProductsValid] = useState(false);
    const [titleValid, setTitleValid] = useState(false);
    const [descriptionValid, setDescriptionValid] = useState(false);
    const [imagesDataValid, setImagesDataValid] = useState(false);
    const [deliveryDetailsValid, setDeliveryDetailsValid] = useState(false);

    const [formIsReady, setFormIsReady] = useState(false);
    const [formIsUpdated, setFormIsUpdated] = useState(false);
    const [loading, setloading] = useState(false);
    const [edrpouLoaded, setEdrpouLoaded] = useState(false);
    const [smallLoader, setSmallLoader] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        setProfProductsValid(seedValid || consultingValid);
    }, [seedValid, consultingValid]);

    useEffect(() => {
        handleDeliveryDetailsValidation();
    }, [delivery_details, delivery_type]);

    useEffect(() => {
        if (formIsReady) {
            sendForm();
            return;
        }
        if (!formIsUpdated && props.application) {
            const { edrpou, name, area, title,
                activity_type,
                seed_and_plant_protection,
                consulting,
                epicenter_certificates,
                fuel_coupons,
                money,
                description,
                delivery_type,
                delivery_details,
                images,
                imagesData,
                imagesSrc,
                user,
                company,
                status,
                chief_name,
                chief_phone_number } = props.application;
            setArea(Number(area).toFixed(2));
            setEdrpou(edrpou);
            setApplicationTitle(title);
            setName(name);
            setActivityType(activity_type);
            setSeed(seed_and_plant_protection);
            setConsulting(consulting);
            setEpicenter(epicenter_certificates);
            setFuel(fuel_coupons);
            setMoney(money);
            setDescription(description);
            setDeliveryType(delivery_type);
            setDeliveryDetails(delivery_details);
            setImagesData(imagesData);
            setImagesId(images);
            setImagesSrc(imagesSrc);
            setUser(user);
            setCompany(company.id);
            setStatus(status);
            setChiefName(chief_name);
            setChiefPhoneNumber(chief_phone_number);
            setFormIsUpdated(true);
            return;
        }
    })

    let formObject = {
        company,
        user,
        edrpou,
        name,
        area,
        activity_type,
        seed_and_plant_protection,
        consulting,
        epicenter_certificates,
        fuel_coupons,
        money,
        delivery_type,
        delivery_details,
        title,
        description,
        imagesData,
        images,
        imagesSrc,
        status,
        chief_name,
        chief_phone_number
    }

    let handleInputObject = {
        setChiefName,
        setChiefPhoneNumber,
        setEdrpou,
        setName,
        setArea,
        setActivityType,
        setSeed,
        setConsulting,
        setEpicenter,
        setFuel,
        setMoney,
        setDeliveryType,
        setDeliveryDetails,
        setApplicationTitle,
        setDescription,
        setImagesData,
    }

    const handleDeliveryChange = event => {
        const { name, value } = event.target;
        setDeliveryDetails(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleDeliveryType = event => {
        setDeliveryType(event.target.value);
        setDeliveryDetails({});
    }

    const handleEdrpou = event => {
        setEdrpou(event.target.value);
        getCompanyByEdrpou(event);
    }

    const formSubmit = () => {
        setloading(true);
        sendImages();
    }

    const sendForm = () => {
        axios.post(URLS.APPLICATIONS_LIST, formObject)
            .then(function (response) {
                navigate(URLS.APPLICATIONS_LIST, { replace: true });
                setloading(false);
                setFormIsReady(false);
                toast.success(t('createdApplication'));
            })
            .catch(function (error) {
                setloading(false);
                setFormIsReady(false);
                if (error.response.status === 403) {
                    toast.error(error.response.data.detail);
                    return;
                }
                toast.error(t('errorMessage'));
            })
    }

    const _next = () => {
        currentStep = currentStep + 1;
        setCurrentStep(currentStep);
        setFormIsReady(false);
    }

    const _prev = () => {
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        setCurrentStep(currentStep);
        setFormIsReady(false);
    }

    const previousButton = () => {
        if (currentStep !== 1) {
            return (
                <button
                    className="btn btn-outline-success"
                    type="button" onClick={_prev}>
                    {t('back')}
                </button>
            )
        }
        return null;
    }

    const nextButton = () => {
        let classNameValid = stepTitle[currentStep - 1].className();

        if (currentStep < 7) {
            return (
                <button
                    className={`btn btn-success float-rigth ${classNameValid}`}
                    type="button" onClick={_next}>
                    {t('next')}
                </button>
            )
        }
        return null;
    }

    const viewButton = () => {
        if (currentStep === 7) {
            let classNameValid = stepTitle[currentStep - 1].className();
            return (
                <>
                    <button type="submit"
                        className={`btn btn-warning btn-block ${classNameValid}`}
                        onClick={() => {
                            setStatus('created')
                            formSubmit();
                            sessionStorage.setItem('tab', 'draft');
                        }}>
                        {t('save')}
                    </button>
                    <button type="submit"
                        className={`btn btn-success btn-block ${classNameValid}`}
                        onClick={() => {
                            setStatus('in_progress');
                            formSubmit();
                            sessionStorage.setItem('tab', 'verification');
                        }}>
                        {t('sendReview')}
                    </button>
                </>
            )
        }
    }

    const handleFileChange = (files) => {
        files = files.map(file => file.file);
        setImagesData(files);
        setImagesDataValid(true);
    }

    const handleDeliveryDetailsValidation = () => {
        if (delivery_details.region &&
            delivery_details.area &&
            delivery_details.city &&
            delivery_details.street &&
            delivery_details.zip_code) {
            setDeliveryDetailsValid(true);
            return;
        }
        if (delivery_details.city &&
            delivery_details.novaposhta) {
            setDeliveryDetailsValid(true);
            return;
        }
        setDeliveryDetailsValid(false);
    }

    const handleValidation = (event) => {
        let bool = event.target.value ? true : false;
        let boolFiles = event.target.files ? true : false;
        let boolCheckbox = event.target.checked ? true : false;
        switch (event.target.name) {
            case 'chief_name':
                setChiefNameValid(bool);
                break;
            case 'chief_phone_number':
                if (event.target.value.length < 17) {
                    setChiefPhoneNumberValid(false);
                    return;
                };
                setChiefPhoneNumberValid(true);
                break;
            case 'edrpou':
                setCompanyNameValid(false);
                break;
            case 'name':
                if (event.target.value.length === 0) {
                    setCompanyNameValid(false);
                    return;
                }
                setCompanyNameValid(true);
                break;
            case 'activity_type':
                setActivityTypeValid(bool);
                break;
            case 'area':
                setAreaValid(bool);
                break;
            case 'seed_and_plant_protection':
                setSeedValid(boolCheckbox);
                break;
            case 'consulting':
                setConsultingValid(boolCheckbox);
                break;
            case 'title':
                setTitleValid(bool);
                break;
            case 'description':
                setDescriptionValid(bool);
                break;
            case 'imagesData':
                setImagesDataValid(boolFiles);
                break;
            default:
        }
    }

    const sendImages = () => {
        let formData = new FormData();
        for (let image of imagesData) {
            formData.append('images', image);
        }
        axiosWrapper(() => {
            axios.post(URLS.IMAGES, formData)
                .then(function (response) {
                    setImagesId(response.data);
                    setFormIsReady(true);
                })
                .catch(function (error) {
                    setloading(false);
                    toast.error(t('errorMessage'));
                    console.log(error);
                });
        })

    }

    const getCompanyByEdrpou = (event) => {
        let edrpou = event.target.value;
        let url = `${URLS.COMPANIES}?search=${edrpou}`;
        setName('');
        if (edrpou.length < 8) return;
        axiosWrapper(() => {
            axios.get(`${url}`)
                .then(res => {
                    if (res.status === 200) {
                        let company = res.data.filter(item => item.edrpou === edrpou);
                        let name = company.length > 0 ? company[0].name : '';
                        let id = company.length > 0 ? company[0].id : '';

                        setName(name);
                        setCompany(id);
                        setCompanyNameValid(name ? true : false);
                        setEdrpouLoaded(true);
                        setEdrpou(edrpou);
                    }
                })
                .catch(err => {
                    console.log(err, 'ERROR')
                })
        })
    }

    let counter = 0;

    const postCompanyByEdrpou = (edrpou) => {
        setSmallLoader(true);
        let url = `${URLS.COMPANIES}`;
        axiosWrapper(() => {
            axios.post(`${url}`, {
                edrpou: edrpou
            })
                .then(res => {
                    if (!res.data && counter < 3) {
                        counter++;
                        postCompanyByEdrpou(edrpou);
                        return;
                    }
                    if (res.data !== "") {
                        counter = 0;
                        let name = res.data.name || '';
                        let id = res.data.id || '';

                        setName(name);
                        setCompany(id);
                        setCompanyNameValid(true);
                        setEdrpouLoaded(true);
                        setEdrpou(edrpou);
                        setSmallLoader(false);
                        return;
                    }
                    toast.error('Компанії з таким ЄДРПОУ не існує');
                    setSmallLoader(false);
                })
                .catch(err => {
                    console.log(err, 'ERROR');
                    setSmallLoader(false);
                })
        })
    }

    const validObjInputs = {
        companyNameValid, activityTypeValid, areaValid, profProductsValid, titleValid,
        descriptionValid, imagesDataValid, chiefNameValid, chiefPhoneNumberValid,
        deliveryDetailsValid
    }

    const stepTitle = [{
        title: t('form.step1.header'),
        description: t('form.step1.description'),
        className: () => disableStepOne(validObjInputs)
    }, {
        title: t('form.step2.header'),
        description: t('form.step2.description'),
        className: () => disableStepTwo(validObjInputs)
    }, {
        title: t('form.step3.header'),
        description: t('form.step3.description'),
        className: () => disableStepThree(validObjInputs)
    }, {
        title: t('form.step4.header'),
        description: t('form.step4.description'),
        className: () => disableStepFour(validObjInputs)
    }, {
        title: t('form.step5.header'),
        description: `${t('form.step5.description1')}
        ${t('form.step5.description2')}`,
        className: () => disableStepFive(validObjInputs)
    }, {
        title: t('form.step6.header'),
        description: t('form.step6.description'),
        className: () => { }
    }, {
        title: '',
        description: '',
        className: () => disableStepSeven(validObjInputs)
    }];

    let stepStyle = {
        width: `calc(${currentStep} * 17%)`
    }

    let stepInfo = stepTitle[currentStep - 1];

    const setFormValid = () => {
        Object.keys(validObjInputs).forEach(input => {
            validObjInputs[input] = true;
        })
    }

    if (currentStep === 7) {
        return (
            <>
                <ConfirmForm form={formObject}
                    currentStep={currentStep}
                    action="create"
                    handleChange={handleInputObject}
                    handleFileChange={handleFileChange}
                    handleDeliveryType={handleDeliveryType}
                    handleValidation={handleValidation}
                />
                <div className="d-flex justify-content-end gap-2">
                    {previousButton()}
                    {viewButton()}
                </div>

                <ToastContainer />
            </>
        )
    }

    if (props.action === 'edit') {
        setFormValid(); // to fix if inputs change
        let classNameValid = stepTitle[currentStep - 1].className();
        return (
            <>
                <ConfirmForm form={formObject}
                    currentStep={7}
                    action='edit'
                    handleChange={handleInputObject}
                    handleDeliveryType={handleDeliveryType}
                    handleDeliveryChange={handleDeliveryChange}
                    handleValidation={handleValidation}
                    handleFileChange={handleFileChange}
                    handleEdrpou={handleEdrpou}
                    setImagesId={setImagesId}
                    setImagesData={setImagesData}
                    setImagesSrc={setImagesSrc}
                />
                <div className="d-flex justify-content-end gap-2">
                    <Link to={`${URLS.APPLICATIONS_LIST}`}
                        className="btn btn-outline-success">{t('cancel')}</Link>

                    <button type="button"
                        className={`btn btn-warning btn-block ${classNameValid}`}
                        onClick={(e) => {
                            sessionStorage.setItem('tab', 'draft');
                            props.submit(e, 'created', imagesData);
                        }}
                        data-body={JSON.stringify(formObject)}>{t('update')}</button>

                    <button type="submit"
                        className={`btn btn-success btn-block ${classNameValid}`}
                        data-body={JSON.stringify(formObject)}
                        onClick={(e) => {
                            setImagesId(props.application.images);
                            sessionStorage.setItem('tab', 'verification');
                            props.submit(e, 'in_progress')
                        }}>
                        {t('sendReview')}
                    </button>
                </div>

                <ToastContainer />
            </>
        )
    }

    return (
        <div className="application-form">
            <div className="left">
                <div className="progress-form">
                    <div className="step-line">
                        <span className="step" style={stepStyle}></span>
                    </div>
                    <span>{currentStep} {t('of')} 6</span>
                </div>
                <div className="step-title">{stepInfo.title}</div>
                <div className="step-description">{stepInfo.description}</div>
            </div>

            <div className="right">
                <div className="form">
                    <div className="form-content">
                        <Step1
                            currentStep={currentStep}
                            handleChange={handleInputObject}
                            form={formObject}
                            handleEdrpou={handleEdrpou}
                            handleValidation={handleValidation}
                            stepTitle={stepInfo.title}
                            postCompanyByEdrpou={postCompanyByEdrpou}
                            edrpou={edrpou}
                            edrpouLoaded={edrpouLoaded}
                            smallLoader={smallLoader}
                        />
                        <Step2
                            currentStep={currentStep}
                            handleChange={handleInputObject}
                            form={formObject}
                            stepTitle={stepInfo.title}
                            handleValidation={handleValidation}
                        />
                        <Step3
                            currentStep={currentStep}
                            handleDeliveryType={handleDeliveryType}
                            handleDeliveryChange={handleDeliveryChange}
                            form={formObject}
                            stepTitle={stepInfo.title}
                        />
                        <Step4
                            currentStep={currentStep}
                            handleChange={handleInputObject}
                            handleValidation={handleValidation}
                            form={formObject}
                            stepTitle={stepInfo.title}
                        />
                        <Step5
                            currentStep={currentStep}
                            handleChange={handleInputObject}
                            handleValidation={handleValidation}
                            form={formObject}
                            stepTitle={stepInfo.title}
                        />
                        <Step6
                            currentStep={currentStep}
                            form={formObject}
                            handleFileChange={handleFileChange}
                            stepTitle={stepInfo.title}
                        />
                    </div>
                    <div className="form-buttons">
                        {previousButton()}
                        {nextButton()}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
export default Form;
