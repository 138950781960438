import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../../../assets/scss/tabs.scss';

import React, { useState } from 'react';
import { t } from 'i18next';

export const TranslateBlock = (props) => {
    const [Tkey, setTkey] = useState('english');
    if (!props.moderation) return;

    return (
        <div className="card">
            <div className="card-body app-validation-card p-0 w-100">
                <div className="block translate-block">
                    <div className="translate-from">
                        <Tabs
                            id="translate-uk-tab"
                            activeKey='ukrainian'
                            className="mb-3"
                        >
                            <Tab eventKey="ukrainian" title={t('ukrainian')}>
                                <UkrainianTab moderation={props.moderation} />
                            </Tab>
                        </Tabs>
                    </div>
                    <div className="translate-to">
                        <Tabs
                            id="translate-other-tab"
                            activeKey={Tkey}
                            onSelect={(k) => setTkey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="english" title={t('english')}>
                                <EnglishTab
                                    translations={props.translations}
                                    setTranslations={props.setTranslations}
                                />
                            </Tab>
                            <Tab eventKey="deutsch" title={t('deutsch')}>
                                <DeutschTab translations={props.translations}
                                    setTranslations={props.setTranslations} />
                            </Tab>
                            <Tab eventKey="french" title={t('french')}>
                                <FrenchTab translations={props.translations}
                                    setTranslations={props.setTranslations}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

const UkrainianTab = (props) => {
    return (
        <>
            <div className='form-group'>
                <label htmlFor='uk-headline'>Заголовок</label>
                <textarea name="uk-headline"
                    id="uk-headline"
                    value={props.moderation.application.title || ''}
                    className="form-control"
                    disabled></textarea>
            </div>
            <div className='form-group'>
                <label htmlFor='uk-description'>Опис</label>
                <textarea name="uk-description"
                    id="uk-description"
                    value={props.moderation.application.description || ''}
                    className="form-control desc"
                    disabled></textarea>
            </div>
        </>
    )
}

const updateTranslations = (event, callback) => {
    let lang = event.target.name.split('-')[0];
    let type = event.target.name.split('-')[1];
    let value = event.target.value;

    callback(prevState => ({
        ...prevState,
        [lang]: {
            ...prevState[lang],
            [type]: value
        }
    }))
}

const EnglishTab = (props) => {
    return (
        <>
            <div className='form-group'>
                <label htmlFor='en-headline'>Headline</label>
                <textarea
                    name="en-headline"
                    id="en-headline"
                    className="form-control"
                    onChange={(event) => {
                        updateTranslations(event, props.setTranslations)
                    }}
                    value={props.translations['en']?.headline || ''}
                ></textarea>
            </div>
            <div className='form-group'>
                <label htmlFor='en-description'>description</label>
                <textarea name="en-description"
                    id="en-description"
                    className="form-control desc"
                    onChange={(event) => {
                        updateTranslations(event, props.setTranslations)
                    }}
                    value={props.translations['en']?.description || ''}
                ></textarea>
            </div>
        </>
    )
}

const DeutschTab = (props) => {
    return (
        <>
            <div className='form-group'>
                <label htmlFor='de-headline'>Headline</label>
                <textarea
                    name="de-headline"
                    id="de-headline"
                    className="form-control"
                    onChange={(event) => {
                        updateTranslations(event, props.setTranslations)
                    }}
                    value={props.translations['de']?.headline || ''}
                ></textarea>
            </div>
            <div className='form-group'>
                <label htmlFor='de-description'>description</label>
                <textarea name="de-description"
                    id="de-description"
                    className="form-control desc"
                    onChange={(event) => {
                        updateTranslations(event, props.setTranslations)
                    }}
                    value={props.translations['de']?.description || ''}
                ></textarea>
            </div>
        </>
    )
}

const FrenchTab = (props) => {
    return (
        <>
            <div className='form-group'>
                <label htmlFor='fr-headline'>Headline</label>
                <textarea
                    name="fr-headline"
                    id="fr-headline"
                    className="form-control"
                    onChange={(event) => {
                        updateTranslations(event, props.setTranslations)
                    }}
                    value={props.translations['fr']?.headline || ''}
                ></textarea>
            </div>
            <div className='form-group'>
                <label htmlFor='fr-description'>description</label>
                <textarea name="fr-description"
                    id="fr-description"
                    className="form-control desc"
                    onChange={(event) => {
                        updateTranslations(event, props.setTranslations)
                    }}
                    value={props.translations['fr']?.description || ''}
                ></textarea>
            </div>
        </>
    )
}