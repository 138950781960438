import React, { useEffect, useState } from 'react';
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";

import axios from 'axios';
import { URLS } from '../../routing/urls';
import { axiosWrapper } from '../../components/Helper';

import { TailSpin } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import { GetApplicationId } from '../../services/Application.service';
import { TabList } from './tabs/Tabs';

import '../../assets/scss/containers/moderations.scss';
import ApplicationSvg from '../../assets/img/application.svg';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const ModerationsDetails = () => {
    let { state } = useLocation();
    const [moderations, setModerations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modLoaded, setModLoaded] = useState(false);
    const [modWaiting, setModWaiting] = useState(false);

    const [currentModeration, setCurrentModeration] = useState();
    const applicationId = GetApplicationId();

    const { t } = useTranslation();

    useEffect(() => {
        getModerations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getModerations = () => {
        let moderatorParam = state?.moderator ? '&show_moderated=true' : '';
        axiosWrapper(() => {
            axios.get(`${URLS.MODERATIONS}?application_id=${applicationId}${moderatorParam}`)
                .then(res => {
                    setModLoaded(true);
                    // if (!checkModerationData(res.data)) {
                    //     toast.warning('Дані ще завантажуються');
                    //     setModWaiting(true);
                    //     setLoading(false);
                    //     return;
                    // };
                    
                    const sortModerations = res.data.sort((a,b) => {
                        return new Date(a.created_at).getTime() - 
                            new Date(b.created_at).getTime()
                    }).reverse();

                    setModerations(sortModerations);
                    setLoading(false);
                    setModWaiting(false);
                    setCurrentModeration(`moderationTab-${res.data[0].id}`)
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                })
        })
    }

    const NoModerationsBlock = () => {
        return (
            <div className='empty-list'>
                <img src={ApplicationSvg} alt="" />
                <span className='text'>{t('noModeration')}</span>
                <button type="button"
                    className='btn btn-success'
                    onClick={createModeration}>{t('createNewModeration')}</button>
            </div>
        )
    }

    const createModeration = () => {
        setLoading(true)
        axiosWrapper(() => {
            axios.post(`${URLS.MODERATIONS}`, { application: applicationId })
                .then(res => {
                    setModWaiting(true);
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                    toast.error(`${t('errorMessage')}, ${t('tryRefresh')}`)
                })
        })
    }

    const WaitingTab = () => {
        return (
            <div className='empty-list'>
                <div>{t('waitForData')}</div>
                <button className='btn btn-outline-success'
                    onClick={getModerations}>
                    {t('checkData')}
                </button>
            </div>
        )
    }

    const checkModerationData = (moderations) => {
        if (!moderations[0].land_bank_received &&
            !moderations[0].enforcement_proceedings_received &&
            !moderations[0].agrarian_receipts_received &&
            !moderations[0].debtors_received) {
            return false;
        }
        return true;

    }

    const ModerationList = (props) => {
        const BtnCreateModeration = () => {
            if (state?.moderationView) return;
            return (
                <button type="button"
                    className='btn btn-success create-btn-media'
                    onClick={createModeration}>
                    {t('createNewModeration')}
                </button>
            )
        }
        return <>
            <div className='d-flex justify-content-between px-md'>
                <div className="page-title">
                    {t('application')} 
                    <span className='grey ms-2'>ID: {applicationId.toString().padStart(4, '0')}</span>
                </div>
                <BtnCreateModeration />
            </div>
            <TabList
                moderations={moderations}
                setModerations={setModerations}
                getModerations={getModerations}
                setLoading={setLoading}
                currentModeration={currentModeration}
                setCurrentModeration={setCurrentModeration}
                moderationView={state?.moderationView}
            />
        </>
    }

    const Main = () => {
        if (!modLoaded) return;
        if (modLoaded &&
            moderations.length === 0 &&
            !modWaiting) {
            return <NoModerationsBlock />
        }
        if (modLoaded &&
            moderations.length !== 0 &&
            !modWaiting) {
            return <ModerationList />
        }
        if (modLoaded && modWaiting) {
            return <WaitingTab />
        }
    }

    return (
        <>
            <Header />
            <main>
                <div className="container">
                    <Main />
                </div>
                <div className={`loader-center ${loading ? '' : 'd-none'}`}>
                    <TailSpin color="grey" ariaLabel="loading-indicator" />
                </div>
            </main>
            <ToastContainer />
            <Footer />
        </>
    )
}