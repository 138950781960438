import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const showConfirmPopup = (confrimOptions) => {
    confirmAlert({
        title: confrimOptions.title,
        message: confrimOptions.message,
        buttons: [
            {
                label: confrimOptions.additional.buttonNoLabel,
                onClick: () => {},
                className: 'btn btn-outline-success'
            },
            {
                label: confrimOptions.additional.buttonYesLabel,
                onClick: () => {
                    let param = null;
                    if (confrimOptions.additional.param) {
                        param = confrimOptions.additional.param;
                    }
                    confrimOptions.callback(param)
                },
                className: 'btn btn-success'
            },

        ]
    });
}