import { Images } from './images'
import { Category } from "./Category";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { URLS } from '../../routing/urls';
import { showConfirmPopup } from '../ConfirmPopup';
import moment from 'moment';

export const Card = (props) => {
    const { t } = useTranslation();

    const confirmOptions = {
        title: t('deleteApplication'),
        message: t('deleteApplicationText'),
        callback: props.deleteApplication,
        additional: {
            param: '',
            buttonNoLabel: t('cancel'),
            buttonYesLabel: t('confirm'),
        }
    }

    const ActionButtons = (props) => {
        if (props.moderation) {
            return (
                <Link to={{
                    pathname: `/${URLS.MODERATIONS_DETAIL}${props.application.id}`,
                }}>
                    {t('moderation')}
                </Link>
            )
        }
        if (props.application.accepted || props.application.rejected) {
            return (
                <Link to={{
                    pathname: `/${URLS.MODERATIONS_DETAIL}${props.application.id}`,
                }}
                    className="view"
                    state={{ 'moderator': true, 'moderationView': true }}>
                    {t('view')}
                </Link>
            )
        }
        if (props.application.status === 'in_progress') {
            return (
                <Link to={{
                    pathname: `/${URLS.APPLICATION_DETAILS}${props.application.id}`,
                }}
                    className="view">
                    {t('view')}
                </Link>
            )
        }
        return (
            <>
                <Link to={{
                    pathname: `edit/${props.application.id}`,
                    state: props.application
                }}
                    className="edit">
                    {t('edit')}
                </Link>
                <button type="button"
                    onClick={() => {
                        confirmOptions.additional.param = props.application.id;
                        showConfirmPopup(confirmOptions)
                    }}
                    className="delete">{t('delete')}</button>
            </>
        )
    }

    const TransformId = (props) => {
        return (
            <div className="application-id">
                ID: {props.id.toString().padStart(4, '0')}
            </div>
        )
    }

    const MomentDate = () => {
        if (props.application?.created_at) {
            return moment(props.application.created_at).format('L');
        }
        return '-'
    }

    return (
        <div className="card application-card">
            <div className="left">
                <div className="title">
                    {props.application.title}
                    <div className="sub-info">
                        <TransformId id={props.application.id} />
                        <span className="application-date">
                            <MomentDate />
                        </span>
                    </div>
                </div>
                <div className="description">{props.application.description}</div>
                <Images images={props.application.images} />
            </div>
            <div className="right">
                <span className="title category-title">{t('category')}</span>
                <Category application={props.application} />
                <div className="d-flex justify-content-between action-buttons">
                    <ActionButtons moderation={props.moderation}
                        application={props.application}
                        deleteApplication={props.deleteApplication}
                    />
                </div>
            </div>
        </div>
    )
}