import Nova from '../../assets/img/nova.svg';
import Address from '../../assets/img/address.svg';
import '../../assets/scss/card.scss';
import { Delivery } from './Delivery';
import { TailSpin } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { LoadImages } from './LoadImages';
import Cleave from 'cleave.js/react';

export const ConfirmForm = props => {
    const { t } = useTranslation();
    const countCheckboxes = () => {
        let counts = 1;
        if (props.form.epicenter_certificates) {
            counts++;
        }
        if (props.form.fuel_coupons) {
            counts++;
        }
        if (props.form.money) {
            counts++;
        }
        return counts;
    }

    const [checkboxesCount, setCheckboxesCount] = useState(countCheckboxes());

    if (props.currentStep !== 7) return null;

    const increaseCheckboxesCount = (bool) => {
        if (bool) {
            setCheckboxesCount(prev => prev + 1)
        } else {
            setCheckboxesCount(prev => prev - 1)
        }
    }

    const deleteExistingImage = (id, file) => {
        props.setImagesData(props.form.imagesData.filter(item => item.id !== id));
        props.setImagesId(props.form.images.filter(item => item !== id));
        props.setImagesSrc(props.form.imagesSrc.filter(item => item !== file));
    }


    const title = () => {
        switch (props.action) {
            case 'create':
                return t('confirmTitleCreate');
            case 'edit':
                return t('confirmTitleEdit');
            case 'view':
                return t('applicationDetail');
            default:
                return t('application');
        }
    }

    return (
        <>
            <form>
                <div className='page-title'>{title()}</div>
                <div className="card">
                    <div className="card-header">{t('form.step1.header')}</div>
                    <div className='card-body'>
                        <fieldset className='form-sub-block'>
                            <legend>{t('chief')}</legend>
                            <div className="form-group">
                                <label htmlFor="chiefName">{t('fullname')}</label>
                                <input
                                    className="form-control"
                                    id="chiefName"
                                    name="chief_name"
                                    type="text"
                                    value={props.form.chief_name}
                                    onChange={e => {
                                        if (props.action === 'view') return;
                                        props.handleChange.setChiefName(e.target.value);
                                        props.handleValidation(e);
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="chiefPhoneNumber">{t('phoneNumber')}</label>
                                <Cleave
                                    className="form-control"
                                    id="chiefPhoneNumber"
                                    name="chief_phone_number"
                                    type="text"
                                    value={props.form.chief_phone_number || '+38'}
                                    onChange={e => {
                                        if (props.action === 'view') return;
                                        props.handleChange.setChiefPhoneNumber(e.target.value);
                                        props.handleValidation(e);
                                    }}
                                    options={{
                                        numericOnly: true,
                                        delimiter: ' ',
                                        prefix: props.form.chief_phone_number?.replace(/\s/g, '') || '+38',
                                        blocks: [3, 3, 3, 2, 2]
                                    }}
                                />
                            </div>
                        </fieldset>
                        <div className="form-group">
                            <label htmlFor="edrpou">{t('edrpouCode')}</label>
                            <input
                                maxLength='10'
                                className="form-control"
                                id="edrpou"
                                name="edrpou"
                                type="text"
                                value={props.form.edrpou}
                                onChange={e => {
                                    props.handleValidation(e);
                                    props.handleEdrpou();
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="company-name">{t('companyName')}</label>
                            <input
                                className="form-control"
                                id="company-name"
                                name="name"
                                type="text"
                                value={props.form.name}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="area">{t('land')}, {t('ha')}</label>
                            <input
                                className="form-control"
                                id="area"
                                name="area"
                                type="number"
                                min='0'
                                value={props.form.area}
                                onChange={e => {
                                    props.handleChange.setArea(e.target.value);
                                    props.handleValidation(e);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                className="form-control"
                                name="id"
                                type="hidden"
                                value={props.form.id}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label>{t('activityType')}</label>
                            <div className="radioButtons">
                                <input
                                    className="form-control"
                                    id="planting"
                                    name="activity_type"
                                    type="radio"
                                    value="planting"
                                    checked={props.form.activity_type === 'planting'}
                                    onChange={e => {
                                        props.handleChange.setActivityType(e.target.value);
                                        props.handleValidation(e);
                                    }}
                                />
                                <label htmlFor="planting">{t('planting')}</label>

                                <input
                                    className="form-control"
                                    id="vegetables"
                                    name="activity_type"
                                    type="radio"
                                    value="vegetables"
                                    checked={props.form.activity_type === 'vegetables'}
                                    onChange={e => {
                                        props.handleChange.setActivityType(e.target.value);
                                        props.handleValidation(e);
                                    }}

                                />
                                <label htmlFor="vegetables">{t('vegetables')}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">{t('category')}</div>
                    <div className='card-body'>
                        <div className="form-group">
                            <span className="form-section-title">{t('profProducts')}</span>
                            <div className="checkboxButtons">
                                <input
                                    className="form-control"
                                    id="seed_and_plant_protection"
                                    name="seed_and_plant_protection"
                                    type="checkbox"
                                    value={props.form.seed_and_plant_protection}
                                    checked={props.form.seed_and_plant_protection}
                                    onChange={e => {
                                        props.handleChange.setSeed(e.target.checked);
                                        props.handleValidation(e)
                                        if (!e.target.checked) return;
                                        props.handleChange.setConsulting(false);
                                    }}

                                />
                                <label htmlFor="seed_and_plant_protection">{t('categoryList.seed_and_plant_protection')}</label>

                                <input
                                    className="form-control"
                                    id="consulting"
                                    name="consulting"
                                    type="checkbox"
                                    value={props.form.consulting}
                                    checked={props.form.consulting}
                                    onChange={e => {
                                        props.handleChange.setConsulting(e.target.checked);
                                        props.handleValidation(e);
                                        if (!e.target.checked) return;
                                        props.handleChange.setSeed(false);
                                    }}
                                />
                                <label htmlFor="consulting">{t('categoryList.consulting')}</label>
                            </div>
                        </div>

                        <div className="form-group">
                            <span className="form-section-title">{t('certificates')}</span>
                            <div className="checkboxButtons">
                                <input
                                    className="form-control"
                                    id="epicenter_certificates"
                                    name="epicenter_certificates"
                                    type="checkbox"
                                    value={props.form.epicenter_certificates}
                                    checked={props.form.epicenter_certificates}
                                    onChange={e => {
                                        if (countCheckboxes() === 3 && e.target.checked) return;
                                        props.handleChange.setEpicenter(e.target.checked);
                                        increaseCheckboxesCount(e.target.checked);
                                    }}
                                />
                                <label htmlFor="epicenter_certificates">{t('categoryList.epicenter_certificates')}</label>

                                <input
                                    className="form-control"
                                    id="fuel_coupons"
                                    name="fuel_coupons"
                                    type="checkbox"
                                    value={props.form.fuel_coupons}
                                    checked={props.form.fuel_coupons}
                                    onChange={e => {
                                        if (countCheckboxes() === 3 && e.target.checked) return;
                                        props.handleChange.setFuel(e.target.checked);
                                        increaseCheckboxesCount(e.target.checked);
                                    }}
                                />
                                <label htmlFor="fuel_coupons">{t('categoryList.fuel_coupons')}</label>
                            </div>
                        </div>

                        <div className="form-group">
                            <span className="form-section-title">{t('finAssist')}</span>
                            <div className="checkboxButtons">
                                <input
                                    className="form-control"
                                    id="money"
                                    name="money"
                                    type="checkbox"
                                    value={props.form.money}
                                    checked={props.form.money}
                                    onChange={e => {
                                        if (countCheckboxes() === 3 && e.target.checked) return;
                                        props.handleChange.setMoney(e.target.checked);
                                        increaseCheckboxesCount(e.target.checked);
                                    }}
                                />
                                <label htmlFor="money">{t('categoryList.money')}</label>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="card">
                    <div className="card-header">{t('form.step3.header')}</div>
                    <div className='card-body'>
                        <div className='deliveries'>
                            <div className="form-group w-50">
                                <input
                                    className="form-control"
                                    id="nova"
                                    name="delivery_type"
                                    type="radio"
                                    value='nova'
                                    checked={props.form.delivery_type === 'nova'}
                                    onChange={props.handleDeliveryType}
                                />
                                <label htmlFor="nova" className="delivery">
                                    <img src={Nova} alt="{t('novaPoshta')}" />
                                    <span>{t('novaPoshta')}</span>
                                </label>
                            </div>

                            <div className="form-group w-50">
                                <input
                                    className="form-control"
                                    id="address"
                                    name="delivery_type"
                                    type="radio"
                                    value='address'
                                    checked={props.form.delivery_type === 'address'}
                                    onChange={props.handleDeliveryType}
                                />
                                <label htmlFor="address" className="delivery">
                                    <img src={Address} alt="Лого адресної доставки" />
                                    <span>{t('address')}</span>
                                </label>
                            </div>
                        </div>
                        <Delivery
                            type={props.form.delivery_type}
                            delivery_details={props.form.delivery_details}
                            handleDeliveryChange={props.handleDeliveryChange}
                            action={props.action}
                        />
                    </div>

                </div>

                <div className='card'>
                    <div className='card-header'>{t('applicationTitle')}</div>
                    <div className='card-body'>
                        <div className="form-group">
                            <input
                                className="form-control"
                                id="title"
                                name="title"
                                type="text"
                                value={props.form.title}
                                onChange={e => {
                                    props.handleChange.setApplicationTitle(e.target.value);
                                    props.handleValidation(e);
                                }}
                            />
                        </div>
                    </div>

                </div>

                <div className='card'>
                    <div className='card-header'>{t('description')}</div>
                    <div className='card-body'>
                        <div className="form-group">
                            <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                value={props.form.description}
                                onChange={e => {
                                    props.handleChange.setDescription(e.target.value);
                                    props.handleValidation(e);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className='card'>
                    <div className='card-header'>{t('attachments')}</div>
                    <div className='card-body'>
                        <div className="form-group images">
                            <LoadImages
                                files={props.form.imagesSrc}
                                imageIds={props.form.images}
                                imagesData={props.form.imagesData}
                                handleFileChange={props.handleFileChange}
                                action={props.action}
                                deleteExistingImage={deleteExistingImage}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <div className={`loader-center ${props.form.loading ? '' : 'd-none'}`}>
                <TailSpin color="grey" ariaLabel="loading-indicator" />
            </div>
        </>
    );
}
