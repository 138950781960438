import axios from "axios";
import { URLS } from "../routing/urls";
import { axiosWrapper } from "../components/Helper";

export class UserService {
    constructor() { }

    async getUserInfo() {
        return new Promise((resolve, reject) => {
            axiosWrapper(() => {
                axios.get(URLS.USER_INFO)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => reject(err))
            });
        })
    }
}