import '../../assets/scss/createApplications.scss';
import Cleave from 'cleave.js/react';
import { useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loader-spinner';

export const Step1 = props => {
    const { t } = useTranslation();
    if (props.currentStep !== 1) {
        return null
    }
    let title = window.innerWidth < 768 ? <div className='page-title title-md-block'>{props.stepTitle}</div> : '';
    return (
        <>
            {title}
            <fieldset className='form-sub-block'>
                <legend>{t('chief')}</legend>
                <div className="form-group">
                    <label htmlFor="chiefName">{t('fullname')}</label>
                    <input
                        className="form-control"
                        id="chiefName"
                        name="chief_name"
                        type="text"
                        value={props.form.chief_name}
                        onChange={e => {
                            props.handleChange.setChiefName(e.target.value);
                            props.handleValidation(e);
                        }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="chiefPhoneNumber">{t('phoneNumber')}</label>
                    <Cleave
                        className="form-control"
                        id="chiefPhoneNumber"
                        name="chief_phone_number"
                        type="text"
                        value={props.form.chief_phone_number}
                        onChange={e => {
                            props.handleChange.setChiefPhoneNumber(e.target.value);
                            props.handleValidation(e);
                        }}
                        options={{
                            numericOnly: true,
                            delimiter: ' ',
                            prefix: '+38',
                            blocks: [3, 3, 3, 2, 2]
                        }}
                    />
                </div>
            </fieldset>

            <div className="form-group">
                <label htmlFor="edrpou">{t('edrpouCode')}</label>
                <Cleave options={{ numericOnly: true }}
                    maxLength='10'
                    className="form-control"
                    id="edrpou"
                    name="edrpou"
                    type="text"
                    value={props.form.edrpou}
                    onChange={(e) => {
                        props.handleEdrpou(e);
                        props.handleValidation(e);
                    }}
                />
            </div>
            <div className="form-group position-relative">
                <label htmlFor="company-name">{t('companyName')}</label>
                <input
                    className="form-control"
                    id="company-name"
                    name="name"
                    type="text"
                    value={props.form.name}
                    onChange={e => {
                        props.handleChange.setName(e.target.value);
                        props.handleValidation(e);
                    }}
                    disabled
                />
                <div className={`small-loader ${props.smallLoader ? 'd-flex' : 'd-none'}`}>
                    <TailSpin color="grey" ariaLabel="loading-indicator" />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="area">{t('land')}, {t('ha')}</label>
                <input
                    className="form-control"
                    id="area"
                    name="area"
                    type="number"
                    min='0'
                    value={props.form.area}
                    onChange={e => {
                        props.handleChange.setArea(e.target.value);
                        props.handleValidation(e);
                    }}
                    onFocus={() => {
                        if (!props.form.name && props.edrpouLoaded) {
                            props.postCompanyByEdrpou(props.edrpou);
                        }
                    }}
                />
            </div>
            <div className="form-group">
                <input
                    className="form-control"
                    name="id"
                    type="hidden"
                    value={props.form.companyId}
                />
            </div>

            <div className="form-group">
                <label>{t('activityType')}</label>
                <div className="radioButtons">
                    <input
                        className="form-control"
                        id="planting"
                        name="activity_type"
                        type="radio"
                        value="planting"
                        checked={props.form.activity_type === 'planting'}
                        onChange={e => {
                            props.handleChange.setActivityType(e.target.value);
                            props.handleValidation(e);
                        }}
                    />
                    <label htmlFor="planting">{t('planting')}</label>

                    <input
                        className="form-control"
                        id="vegetables"
                        name="activity_type"
                        type="radio"
                        value="vegetables"
                        checked={props.form.activity_type === 'vegetables'}
                        onChange={e => {
                            props.handleChange.setActivityType(e.target.value);
                            props.handleValidation(e);
                        }}
                    />
                    <label htmlFor="vegetables">{t('vegetables')}</label>
                </div>
            </div>
        </>
    );
}
