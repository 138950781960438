import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../../../assets/scss/tabs.scss';
import moment from 'moment';
import 'moment/locale/uk';

import axios from 'axios';
import { URLS } from '../../../routing/urls';
import { toast } from 'react-toastify';

import { ApplicationTab } from './Application';
import { BudgetTab } from './Budget';
import { TranslateTab } from './Translate';
import { axiosWrapper } from '../../../components/Helper';
import { CommentsService } from '../../../services/Comment.service';
import '../../../assets/scss/containers/moderations.scss';
import { useTranslation } from 'react-i18next';

export const TabList = (props) => {
    const [tKey, setTKey] = useState('applicationTab');
    const [mKey, setMKey] = useState(props.currentModeration);
    const [comments, setComments] = useState([]);
    const { t } = useTranslation();

    const comService = new CommentsService();

    const getComments = () => {
        axiosWrapper(() => {
            comService.getComments(mKey.split('-')[1])
                .then(res => {
                    setComments(res.data);
                })
                .catch(err => {
                    console.log(err)
                })
        })
    }

    useEffect(() => {
        getComments();
    }, [])

    const tabTitle = (moderation) => {
        let createdAt = moment(moderation.created_at).locale('uk');
        return (
            <div className={`modetarion-tab ${moderation.status === 'accepted' ? 'active' : ''}`}
                title={`${moderation.status === 'accepted' ? 'Модерація активна' : ''}`}>
                {moderation.comments.length !== 0 && <span className='comments-count'></span>}
                <span>{createdAt.format('DD.MM.YYYY')}</span>
                <span>{createdAt.format('HH:mm:ss')}</span>
            </div>
        )
    }

    const deleteModeration = (e) => {
        let id = e.target.dataset.id;
        props.setLoading(true);
        axiosWrapper(() => {
            axios.delete(`${URLS.MODERATIONS}detail/${id}/`)
                .then(res => {
                    props.getModerations();
                    toast.success('Модерацію видалено');
                })
                .catch(err => {
                    console.log(err);
                    props.setLoading(false);
                    toast.error('Модерацію не видалено');
                })
        })
    }

    const BtnDeleteModeration = (props) => {
        if (props.moderationView) return;
        return (
            <button type="button"
                className="btn btn-danger delete-btn-media"
                onClick={deleteModeration}
                data-id={props.moderationId}>
                {t('deleteModeration')}
            </button>
        )
    }

    return (
        <Tabs
            id="moderations-tab"
            activeKey={mKey}
            onSelect={k => {
                setMKey(k);
                props.setCurrentModeration(k);
                getComments();
            }}
            className="mb-3"
        >
            {props.moderations.map(moderation => (
                <Tab eventKey={`moderationTab-${moderation.id}`}
                    title={tabTitle(moderation)}
                    key={`moderation-${moderation.id}`}>
                    <div className='text-end px-md'>
                        <BtnDeleteModeration  
                            moderationId={moderation.id}
                            moderationView={props.moderationView}
                        />
                    </div>

                    <Tabs
                        id="validation-tab"
                        activeKey={tKey}
                        onSelect={(k) => {
                            setTKey(k)
                        }}
                        className="mb-3"
                    >
                        <Tab eventKey="applicationTab" title={t('application')}>
                            <ApplicationTab
                                moderation={moderation}
                                setModerations={props.setModerations}
                                comments={comments}
                                setComments={setComments}
                                getComments={getComments}
                                setLoading={props.setLoading}
                                currentModerationId={Number(mKey.split('-')[1])}
                                lastModeration={props.moderations[0]}
                                moderationView={props.moderationView}
                            />
                        </Tab>
                        <Tab eventKey="budgetTab" title={t('budget')}>
                            <BudgetTab
                                moderation={moderation}
                                comments={comments}
                                setComments={setComments}
                                getComments={getComments}
                                setLoading={props.setLoading}
                                currentModerationId={Number(mKey.split('-')[1])}
                                lastModeration={props.moderations[0]}
                                moderationView={props.moderationView}
                            />
                        </Tab>
                        <Tab eventKey="translateTab" title={t('translations')}>
                            <TranslateTab
                                moderation={moderation}
                                comments={comments}
                                setComments={setComments}
                                getComments={getComments}
                                setLoading={props.setLoading}
                                currentModerationId={Number(mKey.split('-')[1])}
                                lastModeration={props.moderations[0]}
                                moderationView={props.moderationView}
                            />
                        </Tab>
                    </Tabs>
                </Tab>
            ))}
        </Tabs>
    )
}