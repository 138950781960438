import axios from 'axios';
import { URLS } from '../../routing/urls';
import { axiosWrapper } from '../../components/Helper';
import { toast } from 'react-toastify';
import { CommentModal } from '../../components/Popup';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

const AcceptButton = (props) => {
    const navigate = useNavigate();
    if (props.moderationView) return;
    const handleClick = () => changeStatus(props.moderationId, 'accepted');

    return (
        <button type="button"
            className={`btn btn-success accept 
                ${props.score === 12 ? '' : 'disabled'}
                ${props.lastModeration ? '' : 'disabled'}`
            }
            onClick={() => {
                handleClick();
                navigate(URLS.APPLICATIONS_LIST);
            }}>
            {t('accept')}
        </button>
    )
}

const RejectButton = (props) => {
    const navigate = useNavigate();
    if (props.moderationView) return;
    const handleClick = () => changeStatus(props.moderationId, 'rejected');

    return (
        <button type="button"
            className={`btn btn-danger reject
                ${props.lastModeration ? '' : 'disabled'}`
            }
            onClick={() => {

                handleClick();
                navigate(URLS.APPLICATIONS_LIST);
            }}>
            {t('reject')}
        </button>
    )
}

const SaveChanges = (props) => {
    if (props.moderationView) return;
    let moderation = props.moderation;
    let body = {};

    const handleClick = () => {
        switch (props.tab) {
            case 'applicationTab':
                body = {
                    'moderating_solutions': props.checkboxes,
                    'territory_type': props.territoryType
                }
                break;
            case 'budgetTab':
                body = {
                    'budget': props.budget,
                    'donation_url': props.donationUrls
                }
                break
            case 'translationTab':
                body = {
                    'translation': props.translations
                }
                break;
            default:
                return;
        }
        saveTab(moderation.id, body);
    }

    return (
        <button type="button"
            className={`btn btn-outline-success
                ${props.lastModeration ? '' : 'disabled'}`
            }
            id="test"
            onClick={handleClick}>
            {props.buttonName}
        </button>)
}

const saveTab = (id, body) => {
    axiosWrapper(() => {
        axios.patch(`${URLS.MODERATIONS}detail/${id}/`, body)
            .then(res => {
                if (res.status !== 200) {
                    toast.error(t('errorMessage'));
                    return;
                }
                toast.success(t('successUpdate'));
            })
            .catch(err => {
                console.log(err);
                toast.error(t('errorMessage'));
            })
    })
}

const changeStatus = (id, status) => {
    const message = status === 'accepted' ? t('accepted') : t('rejected');
    axiosWrapper(() => {
        axios.patch(`${URLS.MODERATIONS}detail/${id}/`, {
            status: status,
            validated_at: new Date().toISOString()
        })
            .then(res => {
                if (res.status !== 200) {
                    toast.error(t('errorMessage'));
                    return;
                }
                sessionStorage.setItem('tab', `${status === 'accepted' ? 'active' : 'rejected'}`);
                toast.success(`${t('moderation')} ${message}`);
            })
    })
}

export const ButtonsPanel = (props) => {
    return (
        <div className='total-form'>
            <div className='content'>
                <SaveChanges
                    moderation={props.moderation}
                    tab={props.currentTab}
                    checkboxes={props.checkboxes}
                    territoryType={props.territoryType}
                    budget={props.budget}
                    translations={props.translations}
                    setLoading={props.setLoading}
                    lastModeration={props.lastModeration}
                    buttonName = {props.buttonName}
                    donationUrls = {props.donationUrls}
                    moderationView={props.moderationView}
                />
                <CommentModal
                    comments={props.comments.general || []}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    block='general'
                    moderationId={props.moderation.id}
                    setLoading={props.setLoading}
                    moderationView={props.moderationView}
                />
                <span>{t('score')}: {props.score} {t('outOf')} 12</span>
                <div className='d-flex gap-2'>
                    <RejectButton
                        moderationId={props.moderation.id}
                        lastModeration={props.lastModeration}
                        moderationView={props.moderationView}
                    />
                    <AcceptButton
                        moderationId={props.moderation.id}
                        score={props.score}
                        lastModeration={props.lastModeration}
                        moderationView={props.moderationView}
                    />
                </div>
            </div>
        </div>
    )
}