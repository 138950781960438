import axios from "axios";
import { getCookie } from "../components/Cookie";
import { URLS } from "../routing/urls";

export class CommentsService {
    getComments = (id) => {
        return axios.get(`${URLS.COMMENTS}?moderation=${id}`)
    }

    postComment = (block, moderation, text) => {
        const userId = getCookie('user_id');
        let body = {
            block,
            moderation,
            text, 
            user: Number(userId)
        }
        return axios.post(`${URLS.COMMENTS}?moderation=${moderation}`, body)
    }

    deleteComment = (commentId) => {
        return axios.delete(`${URLS.COMMENTS}detail/${commentId}`)
    }
}
