import axios from "axios";
import { setCookie, getCookie } from "./Cookie";
import { URLS } from "../routing/urls";
import { toast } from 'react-toastify';
import { setHeaders } from './Headers';
import { removeUser } from "../services/Auth.service";

export const axiosWrapper = (callback) => {
    const data = {
        "token": getCookie('access_token')
    }
    axios.post(URLS.VERIFY, data)
        .then(res => {
            if (res.status !== 200) {
                refreshToken(callback);
                return;
            }
            setHeaders();
            callback();
        })
        .catch(err => {
            if (err.response.status === 400) {
                window.location = '/login';
                removeUser();
            }
            if (err.response.data.code === 'token_not_valid') {
                refreshToken(callback);
            }
        })
}

const refreshToken = (callback) => {
    const data = {
        "refresh": getCookie('refresh_token')
    }
    axios.post(URLS.REFRESH, data)
        .then(res => {
            if (res.status !== 200) {
                toast.error('Виникла проблема авторизації.');
                return;
            }
            setTokens(res.data.access, res.data.refresh);
            setUserId(res.data?.user_id);
            setHeaders();
            callback();
        })
        .catch(err => {
            console.log(err);
            toast.error('Виникла проблема авторизації.');
            window.location = '/login';
            removeUser();
        })
}

export const setTokens = (access_token, refresh_token) => {
    setCookie('access_token', access_token, { secure: false, 'max-age': (5*60*1000) }); // 5 minutes
    setCookie('refresh_token', refresh_token, { secure: false, 'max-age': (1440*7*60*1000) }); // 7 days
}

export const setUserId = (id) => {
    setCookie('user_id', id, { secure: false, 'max-age': (1440*7*60*1000) });
}

export const getLandInUseArea = (factArea, userArea) => {
    if (!userArea && !factArea) return '-';
    if (!factArea) return Number(userArea).toFixed(2);
    if (!userArea) return Number(factArea).toFixed(2);
    return Math.min(Number(userArea), Number(factArea)).toFixed(2);
}