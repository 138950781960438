import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import { Logout, getUser, removeUser } from '../services/Auth.service';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';

import 'react-dropdown/style.css';
import '../assets/scss/dropdown.scss';
import { axiosWrapper } from "./Helper";
import axios from "axios";
import { toast } from "react-toastify";
import { URLS } from "../routing/urls";

export const ApplicationDropdown = (props) => {
    const { t } = useTranslation();
    const userAuth = getUser();
    if (!userAuth) return;

    const applicationCount = props.applicationCount
        ?
        <span className="circle">{props.applicationCount}</span>
        :
        '';

    const ModerationLink = () => {
        if (props.userInfo.role !== 'moderator') return null;
        return <Link to='/moderations'>{t('moderation')}</Link>
    }

    const ApplicationLink = () => {
        return <Link to='/applications/list'>
            <div className="link-gap">
                {t('myApplications')}
                {applicationCount}
            </div>
        </Link>
    }

    const ApplicationCreateLink = () => {
        return <Link className="btn btn-success"
            to='/applications/create'>+ {t('createNewApplication')}</Link>
    }

    return (
        <Menu menuButton={
            <MenuButton className={'application-dropdown'}>{t('applications')}</MenuButton>
        } className={'application-dropdown-list'}>
            <MenuItem><ApplicationLink /></MenuItem>
            <MenuItem><ModerationLink /></MenuItem>
            <MenuItem><ApplicationCreateLink /></MenuItem>
        </Menu>
    )
}

export const LanguageDropdown = (props) => {
    const { i18n } = useTranslation();
    const [icon, setIcon] = useState(`${i18n.language}-option`);

    if (props.userType === 'donor') return;

    const options = [
        { value: 'ua', label: 'ua', className: 'ua-option' },
        { value: 'en', label: 'en', className: 'en-option' },
    ]

    const defaultOption = options.filter(option => i18n.language === option.value)[0];

    return <Dropdown
        options={options}
        onChange={(event) => {
            i18n.changeLanguage(event.value);
            setIcon(`${event.value}-option`)
        }}
        value={defaultOption}
        controlClassName='language-dropdown'
        menuClassName='language-dropdown-menu'
        arrowClosed={<span className="language-arrow-closed" />}
        arrowOpen={<span className="language-arrow-open" />}
        placeholderClassName={icon}
    />
}

export const UserDropdown = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userAuth = getUser();
    if (!userAuth) return;
    const options = [
        { value: 'logout', label: t('logout'), className: 'logout' }
    ];

    const defaultOption = ' ';

    return <Dropdown options={options}
        arrowClassName='no-arrow'
        controlClassName='custom-dropdown'
        menuClassName='custom-dropdown-menu'
        value={defaultOption}
        onChange={(event) => {
            if (event.value === 'logout') {
                Logout();
                navigate('/login');
                removeUser();
            }
        }}
        placeholderClassName='userImage'
    />
}

export const AdminDropdown = () => {
    const { t } = useTranslation();

    const exportAllApplication = (e) => {
        e.preventDefault();
        const url = `/${URLS.APPLICATIONS_LIST}export/xlsx/`;
        axiosWrapper(() => {
            axios.get(url, {
                method: 'GET',
                responseType: 'blob',
            })
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Export.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => {
                    toast.error(t('errorMessage'));
                })
        })
    }

    const exportAllDonates = (e) => {
        e.preventDefault();
        const url = `/${URLS.MODERATIONS}donations-export/`;
        axiosWrapper(() => {
            axios.get(url, {
                method: 'GET',
                responseType: 'blob',
            })
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Donates.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => {
                    toast.error(t('errorMessage'));
                })
        })
    }


    const ExportApllicationsLink = () => {
        return <button type="button"
            className="btn btn-link"
            onClick={exportAllApplication}>
            {t('exportApplications')}
        </button>
    }

    const ExportDonates = () => {
        return <button type="button"
            className="btn btn-link"
            onClick={exportAllDonates}>
            {t('exportDonates')}
        </button>
    }

    const ImportDonates = () => {
        return <Link className="btn btn-link" 
            to="/donation/import">
            {t('importDonates')}
        </Link>
    }

    return (
        <Menu menuButton={
            <MenuButton className={'application-dropdown'}>{t('admin')}</MenuButton>
        } className={'application-dropdown-list'}>
            <MenuItem><ExportDonates /></MenuItem>
            <MenuItem><ImportDonates /></MenuItem>
            <MenuItem><ExportApllicationsLink /></MenuItem>
        </Menu>
    )
}