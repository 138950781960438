import React, { useEffect, useState } from 'react';
import { EdrBlock } from '../blocks/EdrBlock';
import { LandBankBlock } from '../blocks/LandBankBlock';
import { ExecProceedBlock } from '../blocks/ExecProceedBlock';
import { DebtorsBlock } from '../blocks/DeptorsBlock';
import { AgroReceiptsBlock } from '../blocks/AgroReceiptsBlock';
import { ButtonsPanel } from '../Buttons';
import { SanctionBlock } from '../blocks/SanctionBlock';
import { useTranslation } from 'react-i18next';
import { DonationBlock } from '../blocks/DonationBlock';

export const ApplicationTab = (props) => {
    const { t } = useTranslation();
    let initialCheckboxes = {
        address: false,
        economicActivities: false,
        registryDate: false,
        lotCount: false,
        lotArea: false,
        inUse: false,
        territoryType: false,
        checkData: false,
        enforcements: false,
        debtors: false,
        agroReceipts: false,
    }
    const [checkboxes, setCheckboxes] = useState(initialCheckboxes);
    const [score, setScore] = useState(0);
    const [territoryType, setTerritoryType] = useState(props.moderation.territory_type);

    useEffect(() => {
        if (props.moderation.id !== props.currentModerationId) return;
        let checkboxesObj = props.moderation.moderating_solutions || initialCheckboxes;
        setCheckboxes(checkboxesObj);
        setScore(getCurrentScore(checkboxesObj));
        sessionStorage.setItem('score', getCurrentScore(checkboxesObj));
    }, [])

    useEffect(() => {
        sessionStorage.setItem('score', score);
    }, [score])

    const getCurrentScore = (checkboxesObj) => {
        return Object.values(checkboxesObj).filter(bool => bool === true).length;
    }

    const handleCheckboxChange = event => {
        const { name } = event.target;
        setCheckboxes(prevState => ({
            ...prevState,
            [name]: event.target.checked
        }));
        event.target.checked ? setScore(count => count + 1) : setScore(count => count - 1);
    }

    return (
        <>
            <form>
                <EdrBlock
                    moderation={props.moderation}
                    checkboxes={checkboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    comments={props.comments.edr || []}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    setLoading={props.setLoading}
                    moderationView={props.moderationView}
                />
                <LandBankBlock
                    checkboxes={checkboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    moderation={props.moderation}
                    comments={props.comments.land_bank || []}
                    setComments={props.setComments}
                    setModerations={props.setModerations}
                    territoryType={territoryType}
                    setTerritoryType={setTerritoryType}
                    getComments={props.getComments}
                    setLoading={props.setLoading}
                    moderationView={props.moderationView}
                />
                <ExecProceedBlock
                    checkboxes={checkboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    moderation={props.moderation}
                    comments={props.comments.enforcements || []}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    setLoading={props.setLoading}
                    moderationView={props.moderationView}
                />
                <DebtorsBlock
                    checkboxes={checkboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    moderation={props.moderation}
                    comments={props.comments.debtors || []}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    setLoading={props.setLoading}
                    moderationView={props.moderationView}
                />
                <AgroReceiptsBlock
                    checkboxes={checkboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    moderation={props.moderation}
                    comments={props.comments.receipts || []}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    setLoading={props.setLoading}
                    moderationView={props.moderationView}
                />
                <SanctionBlock
                    checkboxes={checkboxes}
                    handleCheckboxChange={handleCheckboxChange}
                    moderation={props.moderation}
                    comments={props.comments.sanctions || []}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    setLoading={props.setLoading}
                    moderationView={props.moderationView}
                />
            </form>
            {!props.moderation?.application?.accepted &&
                <ButtonsPanel
                    score={score}
                    moderation={props.moderation}
                    comments={props.comments}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    currentTab='applicationTab'
                    checkboxes={checkboxes}
                    territoryType={territoryType}
                    setLoading={props.setLoading}
                    lastModeration={props.lastModeration.id === props.currentModerationId}
                    buttonName={`${t('saveChanges')} ${t('ofBlock')} "${t('application')}"`}
                    moderationView={props.moderationView}
                />
            }
        </>
    )
}