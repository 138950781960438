import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import {
    AdminDropdown,
    ApplicationDropdown,
    LanguageDropdown,
    UserDropdown
} from "../components/Dropdown";

import 'react-dropdown/style.css';
import '../assets/scss/header.scss';
import '../assets/scss/dropdown.scss';
import { UserService } from "../services/User.service";
import { getUser } from "../services/Auth.service";
import { Link } from "react-router-dom";

const Header = (props) => {
    const { t } = useTranslation();
    const [navIsOpen, setNavIsOpen] = useState(false);
    const user = new UserService();
    const [userInfo, setUserInfo] = useState();
    const userAuth = getUser();
    const headerClass = props.onlyLogo ? 'd-none' : '';

    const openNav = () => {
        setNavIsOpen(!navIsOpen);
    }

    if (!userInfo && userAuth) {
        let userData = user.getUserInfo();
        userData.then(res => setUserInfo(res.data))
    }

    let navClassName = navIsOpen ? 'opened' : '';

    return (
        <header>
            <nav>
                <Link to='/'>
                    <h1 className="logo">FARMERHOOD</h1>
                </Link>
                <ul className={`${navClassName}`}>
                    {userInfo?.role === 'moderator'
                        ? <li><AdminDropdown /></li>
                        : ''
                    }
                    <li className={`${headerClass}`}>
                        <ApplicationDropdown
                            applicationCount={props.applicationCount}
                            userInfo={userInfo}
                        />
                    </li>
                    <li>
                        <LanguageDropdown
                            userType={props.userType}
                        />
                    </li>
                    <li className={`${headerClass}`}>
                        <UserDropdown />
                    </li>
                </ul>
                <div className={`mobile-nav ${navClassName}`}
                    onClick={openNav}>
                    <span className='burger'>
                        <span className="line"></span>
                        <span className="line"></span>
                    </span>
                    {t('menu')}
                </div>
            </nav>
        </header>
    )
}

export default Header;
