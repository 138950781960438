import React, { useState } from 'react';
import { BudgetBlock } from "../blocks/BudgetBlock";
import { ButtonsPanel } from '../Buttons';
import { useTranslation } from 'react-i18next';

export const BudgetTab = (props) => {
    const { t } = useTranslation();
    const [budget, setBudget] = useState(props.moderation.budget);
    const [assignedInputs, setAssignedInputs] = useState({
        cash_support: budget.assigned?.cash_support || 0,
        epicenter: budget.assigned?.epicenter || 0,
        seeds: budget.assigned?.seeds || 0,
        consulting: budget.assigned?.consulting || 0,
        fuel: budget.assigned?.fuel || 0
    })

    const [donationUrls, setDonationUrls] = useState(props.moderation.donation_url || {})

    const [donationInputs, setDonationInputs] = useState({
        cash_support: donationUrls.cash_support || '',
        epicenter: donationUrls.epicenter || '',
        seeds: donationUrls.seeds || '',
        consulting: donationUrls.consulting || '',
        fuel: donationUrls.fuel || ''
    })

    return (
        <>
            <form>
                <BudgetBlock
                    moderation={props.moderation}
                    budget={budget}
                    setBudget={setBudget}
                    assignedInputs={assignedInputs}
                    setAssignedInputs={setAssignedInputs}
                    donationUrls={donationUrls}
                    setDonationUrls={setDonationUrls}
                    donationInputs={donationInputs}
                    setDonationInputs={setDonationInputs}
                />
            </form>
            {!props.moderation?.application?.accepted &&
                <ButtonsPanel
                    score={Number(sessionStorage.getItem('score'))}
                    moderation={props.moderation}
                    comments={props.comments}
                    setComments={props.setComments}
                    getComments={props.getComments}
                    currentTab='budgetTab'
                    budget={budget}
                    setLoading={props.setLoading}
                    lastModeration={props.lastModeration.id === props.currentModerationId}
                    buttonName={`${t('saveChanges')} ${t('ofBlock')} "${t('budget')}"`}
                    donationUrls={donationUrls}
                    moderationView={props.moderationView}
                />
            }
        </>

    )
}