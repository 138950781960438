import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { URLS } from '../../routing/urls';

export const Delivery = props => {
    const { t } = useTranslation();
    let [cities, setCities] = useState([]);
    let [warehouses, setWarehouses] = useState([]);
    let [cityVal, setCityVal] = useState("");

    useEffect(() => {
        getNovaPostSettlements("");
    }, [])

    const getNovaPostSettlements = (city) => {
        let data = {
            "apiKey": process.env.REACT_APP_NOVA_POST_API_KEY,
            "modelName": "Address",
            "calledMethod": "getCities",
            "methodProperties": {
                "Page": "1",
                "FindByString": city,
                "Limit": "50"
            }
        }

        fetch(URLS.NOVA_POST_API, {
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(res => {
            let options = [];
            for (let city of res.data) {
                options = [...options, city.Description]
            }
            setCities(options);
        })
        .catch(err => {
            console.log(err)
            toast.error('Виникла помилка');
        })

    }

    const getNovaPostNumber = (city, number) => {
        let data = {
            "apiKey": process.env.REACT_APP_NOVA_POST_API_KEY,
            "modelName": "Address",
            "calledMethod": "getWarehouses",
            "methodProperties": {
                "CityName": city,
                "Page": "1",
                "Limit": "100",
                "Language": "UA",
                "WarehouseId": number
            }
        }

        fetch(URLS.NOVA_POST_API, {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(res => {
                let options = [];
                for (let warehouse of res.data) {
                    options = [...options,
                    `Відділення №${warehouse['Number']}, ${warehouse.ShortAddress}`
                    ]
                }
                setWarehouses(options);
            })
            .catch(err => {
                console.log(err)
                toast.error('Виникла помилка');
            })
    }

    function novaForm() {
        return (
            <div className='d-flex flex-column gap-2'>
                <Autocomplete
                    id="nova-post-city"
                    options={cities}
                    sx={{ width: "100%" }}
                    renderInput={(params) => {
                        return <TextField {...params} name="city"
                            label="Оберіть місто"
                        />
                    }}
                    inputValue={props.delivery_details.city || ""}
                    onInputChange={(e, val) => {
                        if (props.action) return;
                        props.handleDeliveryChange({target: {name: "city", value: val}});
                        getNovaPostSettlements(val);
                    }}
                    onChange={(e, val) => {
                        setCityVal(val);
                        getNovaPostNumber(val, "");
                        props.handleDeliveryChange({target: {name: "city", value: val}});
                    }}
                />
                <Autocomplete
                    id="nova-post-number"
                    options={warehouses}
                    sx={{ width: "100%" }}
                    renderInput={(params) => <TextField {...params} name="novaposhta"
                        label="Оберіть відділення"
                    />}
                    inputValue={props.delivery_details.novaposhta || ""}
                    onInputChange={(e, val) => {
                        if (props.action) return;
                        props.handleDeliveryChange({target: {name: "novaposhta", value: val}});
                        getNovaPostNumber(cityVal, val);
                    }}
                    onChange={(e, val) => {
                        props.handleDeliveryChange({target: {name: "novaposhta", value: val}});
                    }}
                />
            </div>

        )
    }

    function addressForm() {
        return (
            <>
                <div className="form-group">
                    <label>{t('region')}</label>
                    <input type="text"
                        value={props.delivery_details.region || ''}
                        className="form-control"
                        name='region'
                        onChange={props.handleDeliveryChange}
                    />
                </div>
                <div className="form-group">
                    <label>{t('district')}</label>
                    <input type="text"
                        value={props.delivery_details.area || ''}
                        className="form-control"
                        name='area'
                        onChange={props.handleDeliveryChange}
                    />
                </div>
                <div className="form-group">
                    <label>{t('addressCity')}</label>
                    <input type="text"
                        value={props.delivery_details.city || ''}
                        className="form-control"
                        name='city'
                        onChange={props.handleDeliveryChange}
                    />
                </div>
                <div className="form-group">
                    <label>{t('street')}</label>
                    <input type="text"
                        value={props.delivery_details.street || ''}
                        className="form-control"
                        name='street'
                        onChange={props.handleDeliveryChange}
                    />
                </div>
                <div className="form-group">
                    <label>{t('zipCode')}</label>
                    <input type="text"
                        value={props.delivery_details.zip_code || ''}
                        className="form-control"
                        name='zip_code'
                        onChange={props.handleDeliveryChange}
                    />
                </div>
            </>
        )
    }
    if (props.type === 'nova') {
        return novaForm();
    }
    if (props.type === 'address') {
        return addressForm();
    }
}
