import React, { useEffect, useState } from "react";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { ToastContainer, toast } from "react-toastify";
import { Map } from "../../components/Map";
import { useTranslation } from "react-i18next";
import ProgressBar from "@ramonak/react-progress-bar";
import '../../assets/scss/containers/donation.scss';
import {
    GetApplicationId,
    getFullApplicationInfo,
} from '../../services/Application.service';
import { Images } from "../../components/Application/images";
import { AgreeCheckbox } from "../moderation/Inputs";
import { DonateModal } from "./Popup";
import { TailSpin } from 'react-loader-spinner';
import moment from "moment";
import { getLandInUseArea } from "../../components/Helper";
import { transliterate } from 'transliteration';
import i18n from "../../i18n";

export const DonationApplicationPage = () => {
    const { t } = useTranslation();

    const [moderation, setModeration] = useState([]);
    const [loading, setLoading] = useState(true)
    const moderationId = GetApplicationId();

    useEffect(() => {
        i18n.changeLanguage('en');
    }, [])

    useEffect(() => {
        setLoading(true)
        let applicationInfo = getFullApplicationInfo(moderationId, true);
        applicationInfo
            .then(res => {
                setModeration(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                toast.error(t('errorMessage'));
                setLoading(false);
            })
    }, [])

    return (
        <>
            <Header onlyLogo={true} userType={'donor'} />
            <main>
                <Map applicationPoints={true}
                    filter={`?moderation_id=${moderationId}`}
                    publicInfo={true}
                    deepState={true}
                />
                <div className="container">
                    <DonationCard moderation={moderation}
                        moderationId={moderationId}
                    />
                </div>
                <ToastContainer />
                <div className={`loader-center ${loading ? '' : 'd-none'}`}>
                    <TailSpin color="grey" ariaLabel="loading-indicator" />
                </div>
            </main>
            <Footer />
        </>
    )
}

const NoFullApplicationInfo = () => {
    const { t } = useTranslation();
    return (
        <div className="empty-list w-100 pt-0">{t('emptyApplicationInfo')}</div>
    )
}

export const DonationCard = (props) => {
    const [checkedCategory, setCheckedCategory] = useState();
    const [agreeCheckbox, setAgreeCheckbox] = useState(false);
    const [btnClass, setBtnClass] = useState('disabled');
    const { t } = useTranslation();

    useEffect(() => {
        handleDonateButton();
    }, [checkedCategory, agreeCheckbox]);

    const categoryName = {
        epicenter_certificates: t('categoryList.epicenter_certificates'),
        fuel_coupons: t('categoryList.fuel_coupons'),
        money: t('categoryList.money'),
        seed_and_plant_protection: t('categoryList.seed_and_plant_protection'),
        consulting: t('categoryList.consulting')
    }

    const categoryIds = {
        epicenter_certificates: '04',
        fuel_coupons: '03',
        money: '05',
        seed_and_plant_protection: '01',
        consulting: '02'
    }

    const handleDonateButton = () => {
        setBtnClass(() => checkedCategory && agreeCheckbox === true ? '' : 'disabled');
    }

    const DonateCheckboxes = (props) => {
        if (props.moderation.length === 0) return <NoFullApplicationInfo />;

        const budgetAssignedName = {
            epicenter_certificates: 'epicenter',
            fuel_coupons: 'fuel',
            money: 'cash_support',
            seed_and_plant_protection: 'seeds',
            consulting: 'consulting'
        }

        let CheckboxInput = (props) => {
            return <div className="form-group without-checkbox p-0" key={props.index}>
                <input type="checkbox"
                    id={props.category}
                    className="form-control"
                    name='category'
                    checked={checkedCategory === props.category}
                    onChange={e => {
                        setCheckedCategory(() => e.target.checked ? e.target.id : '');
                    }}
                    disabled={props.budget?.collected[props.category] >= props.budget?.assigned[budgetAssignedName[props.category]]
                    }
                />
                <label htmlFor={props.category}>{categoryName[props.category]}</label>
            </div>
        }
        return (
            <>
                {['consulting', 'epicenter_certificates',
                    'fuel_coupons', 'seed_and_plant_protection',
                    'money'].map((category, index) => {
                        if (!props.moderation.application[category]) return;

                        return <CheckboxInput category={category}
                            budget={props.moderation.budget}
                            index={index} key={index} />
                    })
                }
            </>
        )
    }

    let application = props.moderation.application || '-';
    let area = getLandInUseArea(application?.drrp?.area, application?.area) || '-';
    let address = transliterate(props.moderation?.application?.company?.raw_details?.address, {
        replacement: 'Latin',
        unknown: '`'
    });

    let collectedSum = `${Object.values(props.moderation?.budget?.collected ||
        {}).reduce((acc, curr) => Number(acc) + Number(curr), 0) || 0}`;

    let assignedSum = `${Object.values(props.moderation?.budget?.assigned ||
        {}).reduce((acc, curr) => Number(acc) + Number(curr), 0) || 0}`;

    let progressSum = (collectedSum / assignedSum) * 100 || 0;

    return (
        <div className="donation">
            <Images images={props.moderation.application?.images || []} />
            <div className="d-flex application-card border-bottom pb-3">
                <div className="w-100">
                    <div className="title-id">
                        <div className="title">{props.moderation.translation?.en?.headline ||
                            props.moderation.application?.title}
                        </div>
                        <div className="sub-info px-md">
                            <span className="application-id">
                                ID: {props.moderation.application?.id.toString().padStart(4, '0')}
                            </span>
                            <span className="application-date">
                                {moment(props.moderation.application?.created_at).format('L')}
                            </span>
                        </div>
                    </div>
                    <div className="description">
                        {props.moderation.translation?.en?.description ||
                            props.moderation.application?.description}
                    </div>
                    <div className="pt-2">
                        <span className="grey text-uppercase">
                            {t('companyName')}:
                        </span>
                        <span className="p-2 text-uppercase">{props.moderation.application?.company?.raw_details?.nameInEnglish?.shortName ||
                            transliterate(props.moderation.application?.company?.name, {
                                replacement: 'Latin',
                                unknown: '`'
                            }) || '-'}
                        </span>
                    </div>
                    <div className="pt-2">
                        <span className="grey text-uppercase">
                            {t('area')}:
                        </span>
                        <span className="p-2 text-uppercase">{area || 0} {t('ha')} / {(area * 2.471).toFixed(2) || 0} {t('acres')}</span>
                    </div>
                </div>
            </div>
            <div className="category-amount">
                <div>
                    <span className="grey-uppercase">{t('category')}</span>
                    <span className="grey-uppercase">{t('need')}</span>
                    <span className="grey-uppercase">{t('collected')}</span>
                </div>

                {['seed_and_plant_protection', 'consulting', 'fuel_coupons',
                    'epicenter_certificates',
                    'money'].map((category, index) => {
                        if (!props.moderation?.application?.[category]) return;

                        const budgetAssignedName = {
                            epicenter_certificates: 'epicenter',
                            fuel_coupons: 'fuel',
                            money: 'cash_support',
                            seed_and_plant_protection: 'seeds',
                            consulting: 'consulting'
                        }

                        return (
                            <div key={index}>
                                <span>
                                    <span className="grey me-2">
                                        ID: {props.moderation.application?.id.toString().padStart(4, '0')}.{categoryIds[category]}
                                    </span>
                                    {categoryName[category]}
                                </span>
                                <span className="text-bold">
                                    ${props.moderation.budget?.assigned?.[budgetAssignedName[category]] || 0}
                                </span>
                                <span className="text-bold">
                                    ${props.moderation.budget?.collected?.[category] || 0}
                                </span>
                            </div>
                        )
                    })
                }


            </div>
            <div className="px-md">
                <ProgressBar completed={Number(progressSum.toFixed(0))}
                    height='8px'
                    bgColor="#46B04A"
                    labelAlignment="outside"
                    labelColor="46B04A"
                    animateOnRender={true}
                />
            </div>

            <div className="grey-block">
                <div className="user unknow text-uppercase">
                    <div>{transliterate(props.moderation.application?.chief_name, {
                        replacement: 'Latin',
                        unknown: '`'
                    }) || '-'}</div>
                </div>
                <div className="verified">{t('identityVerified')}</div>
                <div className="location text-uppercase">
                    <div>{address?.split(',').slice(0, 4).join(',') || '-'}</div>
                </div>
                <div className="text-center">
                    <label className="grey-uppercase">{t('sum')}</label>
                    <div className="text-bigger text-bold">
                        ${assignedSum}
                    </div>
                </div>
                <div className="text-center">
                    <label className="grey-uppercase">{t('collected')}</label>
                    <div className="text-bigger grey">
                        ${collectedSum}
                    </div>
                </div>
            </div>
            <div className="text-bigger text-center">{t('fundCategories')}</div>
            <div className="row-gap justify-content-center px-md">
                <DonateCheckboxes moderation={props.moderation}
                />
            </div>
            <div className="d-flex justify-content-between align-items-center px-md">
                {props.moderation.length !== 0
                    ? <AgreeCheckbox handleAgreeCheckbox={setAgreeCheckbox} />
                    : ''}
                <DonateModal btnClass={btnClass}
                    moderationId={props.moderationId}
                    checkedCategory={checkedCategory}
                />
            </div>
        </div>
    )
}