import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import { URLS } from '../routing/urls';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as turf from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../assets/scss/map.scss';
import '../assets/scss/popup.scss';
import { axiosWrapper } from './Helper';
import { getFullApplicationInfo } from '../services/Application.service';
import { MapPopupDetail } from './Popup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import pointIcon from '../assets/img/map-point.png';
import pointIconSelected from '../assets/img/map-point-select.png';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export const Map = React.memo((props) => {
    const { t } = useTranslation();
    const mapContainerRef = useRef(null);
    const popUpRef = useRef(new mapboxgl.Popup({ offset: 15 }))

    const [lng, setLng] = useState(32);
    const [lat, setLat] = useState(49);
    const [zoom, setZoom] = useState(3.5);

    const [popupInfo, setPopupInfo] = useState(null);

    const [points, setPoints] = useState({});

    // Initialize map when component mounts
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/light-v10',
            center: [lng, lat],
            zoom: zoom
        });

        map.on('move', () => {
            setLng(map.getCenter().lng.toFixed(4));
            setLat(map.getCenter().lat.toFixed(4));
            setZoom(map.getZoom().toFixed(2));
        });

        map.on('load', () => {
            if (props.setLoading) {
                props.setLoading(false);
            }
            if (props.applicationPoints) {
                getAllApplicationPoints(props.filter, props.publicInfo);
            }
            if (!props.deepState) return;
            getDeepStatePolygons();
            if (!props.pointsData) return;
            getLotPoints();
        })

        const getDeepStatePolygons = () => {
            axios.get(URLS.DEEP_STATE)
                .then(geojson => {
                    setDeepStatePolygons(geojson.data.map);
                    setBufferPolygons(geojson.data.map);
                })
                .catch(err => {
                    console.log(err)
                })
        }

        const setDeepStatePolygons = (geojson) => {
            map.addSource('deepStatePolygons', {
                type: 'geojson',
                data: geojson
            });

            map.addLayer({
                'id': 'deepStatePolygons-layer',
                'type': 'fill',
                'source': 'deepStatePolygons',
                'paint': {
                    'fill-color': ['get', 'fill'],
                    'fill-opacity': 0.5
                }
            });
        }

        const setBufferPolygons = (geojson) => {
            let territory = { type: 'FeatureCollection', features: [] };

            geojson.features.forEach(feature => {
                let name = feature.properties.name;
                if (name.startsWith('Звільнено') ||
                    name.startsWith('ОРДЛО') ||
                    name.startsWith('Ситуація невідома') ||
                    name.startsWith('Окупован')) {
                    territory.features = [...territory.features, feature];
                }
            })

            let buffered = turf.buffer(territory, 49, { units: 'kilometers' });
            buffered = turf.combine(buffered);
            let solidBuffer = turf.buffer(buffered, 1, { units: 'kilometers' });

            map.addSource('bufferPolygons', {
                type: 'geojson',
                data: solidBuffer
            });

            map.addLayer({
                'id': 'bufferPolygons-layer',
                'type': 'line',
                'source': 'bufferPolygons',
                'paint': {
                    'line-color': '#000000',
                    'line-opacity': 0.5,
                    'line-dasharray': [2, 1],
                }
            });
        }

        const getLotPoints = () => {
            setPoints(props.pointsData);
            setLotPoints(processBudgetStatus(props.pointsData));
        }

        const setIconWithLocationOnMap = (featureId) => {
            map.setLayoutProperty('points-layer', 'icon-image', [
                'match',
                ['id'],
                featureId, 'point-icon-selected',
                'point-icon'
            ])
            map.setLayoutProperty('points-layer', 'icon-offset', [
                'match',
                ['id'],
                featureId, ["literal", [0, -23]],
                ["literal", [0, 0]]
            ])
        }

        const unsetAllLocationIcon = () => {
            map.setLayoutProperty('points-layer', 'icon-image', 'point-icon');
            map.setLayoutProperty('points-layer', 'icon-offset', [0, 0])
        }

        const processBudgetStatus = (geojson) => {
            geojson.features.forEach(function (feature) {
                const collected = feature.properties.budget?.collected;
                const assigned = feature.properties.budget?.assigned;
                if (!collected || !assigned) return geojson;
                let budgetBoolArray = [];

                Object.keys(collected).forEach(category => {
                    if (!Number(assigned[category])) return;
                    budgetBoolArray = [...budgetBoolArray, Number(collected[category]) >= Number(assigned[category])]
                })
                feature.properties.donate_status = checkBudgetStatus(budgetBoolArray)
            })
            return geojson
        }

        const checkBudgetStatus = (budgetBoolArray) => {
            const hasTrue = budgetBoolArray.includes(true);
            const hasFalse = budgetBoolArray.includes(false);

            if (hasTrue && hasFalse) {
                return 'half';
            } else if (hasTrue) {
                const allTrue = budgetBoolArray.every(item => item === true);
                if (allTrue) {
                    return 'full';
                } else {
                    return 'half';
                }
            } else {
                return 'empty';
            }
        }

        const movePointsLayerToTop = () => {
            map.moveLayer('points-layer', '');
        }

        const setLotPoints = (geojson, popupContent = false) => {
            map.addSource('points', {
                type: 'geojson',
                data: geojson,
                generateId: true
            });

            [{ 'point-icon': pointIcon },
            { 'point-icon-selected': pointIconSelected }].forEach(icon => {
                map.loadImage(Object.values(icon)[0], (error, image) => {
                    if (error) throw error;
                    map.addImage(Object.keys(icon)[0], image, { sdf: true });
                });
            })

            map.addLayer({
                'id': 'points-layer',
                'type': 'symbol',
                'source': 'points',
                'layout': {
                    'icon-image': 'point-icon',
                    'icon-size': 0.7,
                    'icon-offset': [0, 0],
                    'icon-allow-overlap': true,
                },
                'paint': {
                    'icon-color': [
                        'match',
                        ['get', 'donate_status'],
                        'half',
                        '#FF8C00',
                        'empty',
                        '#46b04a',
                        'full',
                        '#0027c4',
                        '#46b04a'
                    ],
                }
            });

            if (!popupContent) return;

            map.on('click', () => {
                unsetAllLocationIcon();
                setPopupInfo(null)
            });

            map.on('click', 'points-layer', (e) => {
                props.setLoading(true);
                setIconWithLocationOnMap(e.features[0].id);
                const moderationId = e.features[0].properties.moderation_id;

                let applicationInfo = getFullApplicationInfo(moderationId, props.publicInfo);
                applicationInfo.then(res => {
                    setPopupInfo(res);
                    props.setLoading(false);
                })
                    .catch(err => {
                        console.log(err);
                        toast.error(t('errorMessage'));
                        props.setLoading(false);
                    })
            })
        }

        const getAllApplicationPoints = (filter = '?', publicInfo = false) => {
            if (publicInfo) {
                axios.get(`${URLS.APPLICATIONS_LIST}geojson/${filter}`)
                    .then(geojson => {
                        setLotPoints(processBudgetStatus(geojson.data), props.popupContent);
                        movePointsLayerToTop();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                axiosWrapper(() => {
                    axios.get(`${URLS.APPLICATIONS_LIST}geojson/${filter}`)
                        .then(geojson => {
                            setLotPoints(processBudgetStatus(geojson.data), props.popupContent);
                            movePointsLayerToTop();
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })
            }
        }

        return () => map.remove();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div ref={mapContainerRef} className={`map-container ${props.height}`}>
                <div className={`mapboxgl-popup ${popupInfo ? '' : 'd-none'}`}>
                    <div className='mapboxgl-popup-content'>
                        {popupInfo && <MapPopupDetail
                            info={popupInfo}
                            setPopupInfo={setPopupInfo}
                        />}
                    </div>
                </div>
            </div>
        </>
    );
});