import React, { Component } from "react";
import Header from '../layouts/Header';
import '../assets/scss/login.scss';
import Auth from '../components/Login/Auth';

export default class Login extends Component {

    render() {
        return (
            <>
                <Header onlyLogo={true}/>
                <main className="bg-image login-content">
                    <div className="container w-500">
                        <Auth />
                    </div>
                </main>
            </>
        )
    }
}
