import React, { useEffect, useState } from "react";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { ToastContainer } from "react-toastify";
import { Map } from "../../components/Map";
import { useTranslation } from "react-i18next";
import { TailSpin } from 'react-loader-spinner';
import i18n from "../../i18n";

export const DonationListPage = () => {
    const { t } = useTranslation();
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        i18n.changeLanguage('en');
    }, [])

    return (
        <>
            <Header onlyLogo={true} userType={'donor'}/>
            <main>
                <div className="container full-page">
                    <div className='page-title mb-3'>{t('helpRebuild')}</div>
                    <div className="text grey-uppercase pb-2 px-md">{t('chooseAapplicationOnMap')}</div>
                    <Map
                        applicationPoints={true}
                        popupContent={true}
                        height='full'
                        loading={loading}
                        setLoading={setLoading}
                        publicInfo={true}
                        deepState={true}
                    />
                </div>
                <ToastContainer />
            </main>
            <div className={`loader-center ${loading ? '' : 'd-none'}`}>
                <TailSpin color="grey" ariaLabel="loading-indicator" />
            </div>
            <Footer />
        </>
    )
}