import Cleave from "cleave.js/react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { URLS } from "../../routing/urls";
import { setTokens } from "../Helper";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { removeUser, setUser } from "../../services/Auth.service";
import { setUserId } from "../Helper";

export const ConfirmForm = props => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    if (!props.success) return null;

    let sendCode = (event) => {
        event.preventDefault();
        let data = {
            user_phone_number: props.user_phone_number,
            confirm_code: props.confirm_code
        }
        axios.post(URLS.LOGIN_CONFIRM, data)
            .then(response => {
                if (response.data.new_code_sent === 'true') {
                    toast.error(t('errorRecentCodeMessage'));
                    return;
                }
                if (response.data.success === 'true') {
                    setTokens(response.data.access_token, response.data.refresh_token);
                    setUserId(response.data.user_id);
                    setUser();
                    if (localStorage.getItem('userType') === 'donor') {
                        navigate("/donation/list/", { replace: true });
                        return;
                    }
                    navigate("../applications", { replace: true });

                } else if (response.data.success === 'false') {
                    toast.error(t('errorCodeConfirmMessage'));
                    removeUser();
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error(t('errorMessage'));
                removeUser();
            });
    }

    let disable = props.codeValid ? '' : 'disabled';
    return (
        <>
            <form onSubmit={sendCode}>
                <div className="form-group">{t('confirmForm.part1')}</div>
                <div className="form-group">
                    <Cleave placeholder={t('confirmForm.sms')}
                        className="form-control"
                        onChange={props.handleCodeChange}
                        maxLength="6"
                        options={{ numericOnly: true }}
                    />
                </div>
                <div className="form-group grey">{t('confirmForm.part2')}</div>
                <div className="form-group">
                    <button
                        type="submit"
                        className={`btn btn-success form-control ${disable}`}>
                        {t('login')} 
                    </button>
                </div>
            </form>
        </>
    );
}

export const LoginForm = props => {
    const { t } = useTranslation();
    if (props.success) return null;
    
    let sendPhoneNumber = (event) => {
        event.preventDefault();
        let data = {
            user_phone_number: props.user_phone_number
        }

        axios.post(URLS.LOGIN, data)
            .then(response => {
                if (response.data.success === 'true') {
                    props.setStatus(true);
                } else if (response.data.success === 'false') {
                    toast.error(t('errorMessage'));
                }
            })
            .catch(function (error) {
                toast.error(t('errorMessage'));
                console.log(error);
            });
    }

    let disable = props.buttonValid ? '' : 'disabled';
    return (
        <>
            <form onSubmit={sendPhoneNumber}>
                <div className="form-group">
                    <Cleave placeholder={t('phoneNumber')}
                        className="form-control"
                        onChange={props.handlePhoneChange}
                        options={{
                            numericOnly: true,
                            delimiter: ' ',
                            prefix: '+38',
                            blocks: [3, 3, 3, 2, 2]
                        }}
                    />
                </div>
                <div className="form-group">
                    <button
                        type="submit"
                        className={`btn btn-success form-control ${disable}`}>
                        {t('login')}  
                    </button>
                </div>
            </form>
        </>
    );
}
