import { ToastContainer } from "react-toastify"
import Header from "../../layouts/Header"
import { TailSpin } from "react-loader-spinner"
import Footer from "../../layouts/Footer"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import '../../assets/scss/files.scss';
import XlsxIcon from '../../assets/img/xlsx.png';
import { axiosWrapper } from "../../components/Helper"
import axios from "axios"
import { URLS } from "../../routing/urls";

export const DonationImportPage = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const fileTypes = ["XLSX"];
    const [file, setFile] = useState(null);

    const DragDrop = (props) => {
        const handleChange = (file) => {
            props.setFile(file);
        };

        const UploadedFile = (props) => {
            const FileItem = () => {
                return (
                    <div className="uploaded-file-block">
                        <img src={XlsxIcon} alt="xslx icon" />
                        <span>{props.file.name}</span>
                    </div>
                )
            }
            return (
                props.file ? <FileItem /> : ""
            )
        }
        return (
            <>
                <FileUploader
                    handleChange={handleChange}
                    name="file" types={fileTypes}
                    classes="drop-area"
                    children={<div>{t('dragOr')}
                        <span className="upload-button"> {t('upload')} </span>
                        {t('file')}
                    </div>}
                />
                <div><UploadedFile file={props.file} /></div>
            </>
        );
    }

    const sendFile = () => {
        setLoading(true)
        const url = `/${URLS.MODERATIONS}donations-import/`;
        let formData = new FormData();
        formData.append('file', file);
        axiosWrapper(() => {
            axios.post(url, formData)
                .then(res => {
                    setLoading(false);
                    handleImportCSVParse(res.data);
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                    toast.error(t('errorMessage'));
                })
        })
    }

    const handleImportCSVParse = (file) => {
        const blob = new Blob([file], { type: 'text/csv' });
        const url = URL.createObjectURL(blob); 
        const link = document.createElement('a'); 
        link.href = url; 
        link.setAttribute('download', 'data.csv'); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            <Header />
            <main>
                <div className="container">
                    <div className='page-title'>{t('importDonates')}</div>
                    <div className="upload-file-wrapper">
                        <DragDrop file={file} setFile={setFile} />
                    </div>
                    <div className="text-end mt-3">
                        <button className="btn btn-success"
                            onClick={sendFile}>
                            {t('download')}
                        </button>
                    </div>
                </div>
                <ToastContainer />
                <div className={`loader-center ${loading ? '' : 'd-none'}`}>
                    <TailSpin color="grey" ariaLabel="loading-indicator" />
                </div>
            </main>
            <Footer />
        </>
    )
}