import { Collapse } from 'react-bootstrap';
import { useState } from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';

export const Images = (props) => {
    const { t } = useTranslation();
    const isDesktop = () => {
        return window.innerWidth < 768 ? false : true;
    }

    const [open, setOpen] = useState(isDesktop());
    const [title, setTitle] = useState(t('showImages'));

    return (
        <>
            <div className="collapse-images-header"
                onClick={() => {
                    setOpen(!open);
                    setTitle(() => {
                        return open ? t('showImages') : t('hideImages')
                    });
                }}
                aria-controls="collapse-images"
                aria-expanded={open}>
                {title}
            </div>
            <Collapse in={open}>
                <div className="collapse-images images">
                    <Carousel
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}>
                        {props.images.map((image, index) => {
                            let src = `${image.image}`;
                            return <a href={src} key={index}>
                                <img src={src} alt={src} />
                            </a>
                        })}
                    </Carousel>
                </div>
            </Collapse>
        </>
    );
}