import { useTranslation } from 'react-i18next';
import '../../../assets/scss/comment.scss';

export const CommentBlock = (props) => {
    const { t } = useTranslation();

    const TitleEmptyCommentBlock = () => {
        return props.moderationView ? t('viewComments') : t('leaveComment')
    }

    const TitleNotEmptyCommmentBlock = (props) => {
        return props.moderationView 
            ? t('viewComments') 
            : `${props.comments.length} ${t('commentOrComments')}`
    }
    
    let comment = <button type="button"
        className="comment"
        onClick={props.onClick}>
        <TitleEmptyCommentBlock />
    </button>

    if (props.comments.length > 0) {
        comment = <button type="button"
            className="comment"
            onClick={props.onClick}>
            <span className='green-circle'></span>
            <span className='d-none-md'>
                <TitleNotEmptyCommmentBlock 
                    comments={props.comments}
                    moderationView={props.moderationView}
                />
            </span>
        </button>
    }
    return comment;
}