import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';

import { CommentBlock } from '../containers/moderation/blocks/Comments';
import '../assets/scss/modal.scss';
import moment from 'moment';

import { axiosWrapper, getLandInUseArea } from './Helper';
import { CommentsService } from '../services/Comment.service';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Images } from "./Application/images";
import { URLS } from "../routing/urls";
import ProgressBar from "@ramonak/react-progress-bar";
import { Link } from "react-router-dom";
import { transliterate } from 'transliteration';

const comService = new CommentsService();

export function CommentModal(props) {
    const [show, setShow] = useState(false);

    const { t } = useTranslation();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const postComment = (block, moderationId, text) => {
        if (!text) return;
        axiosWrapper(() => {
            comService.postComment(block, moderationId, text)
                .then(res => {
                    let block = res.data.block;
                    let obj = {
                        id: res.data.id,
                        moderation: res.data.moderation,
                        text: res.data.text,
                        created_at: res.data.created_at,
                        role: res.data.user?.role,
                        phone_number: res.data.user?.phone_number
                    }
                    if (props.comments.length > 0) {
                        props.setComments(prevState => ({
                            ...prevState, [block]: [
                                ...prevState[block],
                                obj
                            ]
                        }));
                    } else {
                        props.setComments(prevState => ({
                            ...prevState, [block]: [obj]
                        }))
                    }
                    handleClose()
                    toast.success(t('addedComment'));
                })
                .catch(err => {
                    console.log(err);
                    handleClose();
                    toast.error(t('noAddedComment'));
                })
        })
    }

    const deleteComment = (commentId) => {
        axiosWrapper(() => {
            comService.deleteComment(commentId)
                .then(res => {
                    toast.success(t('deletedComment'));
                    handleClose();
                    props.getComments();
                })
                .catch(err => {
                    console.log(err);
                    handleClose();
                    toast.error(t('noDeletedComment'));
                })
        })
    }

    const userRole = (role) => {
        if (!role) return;
        switch (role) {
            case 'moderator':
                return t('moderator');
            case 'farmer':
                return t('farmer');
            case 'benefactor':
                return t('benefactor');
            default:
                return '-';
        }
    }

    const Content = () => {
        let comments = props.comments;
        if (comments.length === 0) {
            return (
                <div className='body-main'>
                    {t('noComments')}
                </div>
            )
        }

        let commentMessage = comments.map(comment => (
            <div key={comment.id}>
                <div className='body-header'>
                    <div className="user-info">
                        <span>{userRole(comment.user?.role) || t('moderator')}</span>
                        <span>{comment.user?.phone_number || t('noPhoneNumber')}</span>
                    </div>
                    <span className='date'>{moment(comment.created_at).format('L')}</span>
                </div>
                <div className='body-main'>
                    <span>{comment.text}</span>
                    {/* <span className='delete'
                        onClick={(event) => {
                            deleteComment(comment.id);
                        }}
                        data-id={comment.id}>
                    </span> */}
                </div>
            </div>
        ))
        return commentMessage;
    }

    const CommentForm = () => {
        const [message, setMessage] = useState('');
        if (props.moderationView) return;
        return (
            <div className='body-footer'>
                <form>
                    <input type="text"
                        name="text"
                        className='form-control'
                        placeholder={t('leaveComment')}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <button type="submit"
                        className='comment-button'
                        onClick={(e) => {
                            e.preventDefault();
                            postComment(props.block, props.moderationId, message);
                        }}></button>
                </form>
            </div>
        )
    }

    return (
        <>
            <CommentBlock comments={props.comments} 
                onClick={handleShow} 
                moderationView={props.moderationView}
            />

            <Modal show={show}
                onHide={handleClose}
                centered
                className='comment-modal'
                dialogClassName="modal-320w">
                <Modal.Header closeButton>
                    <Modal.Title>{t('comments')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Content />
                    <CommentForm />
                </Modal.Body>
            </Modal>
        </>
    );
}

export const MapPopupDetail = (props) => {
    const data = props.info.data;
    const { t } = useTranslation();

    const area = getLandInUseArea(data.application.drrp?.area, data.application?.area);
    const address = transliterate(data.application?.company.raw_details?.address, {
        replacement: 'Latin',
        unknown: '`'
    });

    let budgetSum = Object.values(data.budget?.assigned ||
        {}).reduce((acc, curr) => Number(acc) + Number(curr), 0) || 0;

    let collectedSum = Object.values(data.budget?.collected ||
        {}).reduce((acc, curr) => Number(acc) + Number(curr), 0) || 0;

    let needToCollectSum = (budgetSum - collectedSum) > 0 
        ? (budgetSum - collectedSum)
        : 0;

    let progressSum = (collectedSum / budgetSum) * 100 || 0;

    let donateDisabledClass = needToCollectSum <= 0 ? 'disabled' : '';
    return (
        <div className="map-popup application-card">
            <div className="title">{data.translation?.en?.headline
                || data.application?.title}</div>
            <div className="sub-info px-md">
                <span className="application-id">
                    ID: {data.application?.id.toString().padStart(4, '0')}
                </span>
                <span className="application-date">
                    {moment(data.application?.created_at).format('L')}
                </span>
            </div>
            <div className="description">{data.translation?.en?.description
                || data.application?.description}</div>
            <div className="area">
                <div className="pt-2">
                    <span className="grey text-uppercase">
                        {t('companyName')}:
                    </span>
                    <span className="p-2 text-uppercase">{data.application?.company?.raw_details?.nameInEnglish?.shortName ||
                        transliterate(data.application?.company?.name, {
                            replacement: 'Latin',
                            unknown: '`',
                        }) || '-'}
                    </span>
                </div>
                <div className="pt-2">
                    <span className="grey text-uppercase">
                        {t('area')}:
                    </span>
                    <span className="p-2">{area || 0} {t('ha')} / {(area * 2.471).toFixed(2) || 0} {t('acres')}</span>
                </div>
            </div>
            <Images images={data.application?.images} />
            <div className="user unknow text-uppercase">
                {transliterate(data.application?.chief_name, {
                    replacement: 'Latin',
                    unknown: '`'
                }) || '-'}
            </div>
            <div className="verified">{t('identityVerified')}</div>
            <div className="location text-uppercase">
                {address?.split(',').slice(0, 4).join(',')}
            </div>
            <div className="line horizontal"></div>
            <div className="d-flex justify-content-between">
                <div className="application-budget">
                    <span className="label">{t('budgetAmount')}</span>
                    <span className="number">
                        {budgetSum}$
                    </span>
                </div>
                <div className="application-budget">
                    <span className="label">{t('stillToCollect')}</span>
                    <span className="number grey">
                        {needToCollectSum}$
                    </span>
                </div>
            </div>

            <ProgressBar completed={Number(progressSum.toFixed(0))}
                height='4px'
                bgColor="#46B04A"
                labelAlignment="outside"
                labelColor="46B04A"
                animateOnRender={true}
            />
            <Link to={`/${URLS.DONATION}${data.id}/`}
                className={`btn btn-success ${donateDisabledClass}`}
            >{t('donate')}</Link>

            <button className="mapboxgl-popup-close-button"
                type="button"
                aria-label="Close popup"
                aria-hidden="true"
                onClick={() => props.setPopupInfo(null)}>×</button>
        </div>
    )
}