import React, { useState, useEffect } from "react";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import '../assets/scss/list.scss';
import axios from 'axios';
import ApplicationSvg from '../assets/img/application.svg';
import { URLS } from "../routing/urls";
import { Link } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/scss/carousel.scss';
import { axiosWrapper } from "../components/Helper";
import Error from "./Error";

import { Card } from "../components/Application/Card";
import { useTranslation } from 'react-i18next';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LazyLoad from 'react-lazyload';

const List = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [applications, setApplications] = useState(true);
    const [error, setError] = useState(false);
    const [applicationTab, setApplicationTab] = useState(sessionStorage.getItem('tab') || 'active');

    useEffect(() => {
        getApplications();
    }, []);

    const getApplications = () => {
        axiosWrapper(() => {
            axios.get(`${URLS.APPLICATIONS_LIST}?ordering=created_at`)
                .then(res => {
                    const applications = res.data;
                    setApplications(applications);
                    setLoading(false)
                })
                .catch(err => {
                    setError(true)
                    setLoading(false)
                    toast.error(t('errorMessage'));
                    console.log(err)
                })
        })
    }

    const deleteApplication = (id) => {
        axios.delete(`${URLS.APPLICATIONS_LIST}detail/${id}`)
            .then(res => {
                if (res.status === 204) {
                    toast.success(t('applicationDeleted'));
                    getApplications();
                }
            })
            .catch(err => {
                toast.error(t('errorMessage'));
                console.log(err)
            })
    }

    const renderApplications = () => {
        if (error) {
            return <Error />
        }
        if (applications.length > 0) {
            return (
                <>
                    <div className="list-page-header">
                        <span className="page-title">{t('myApplications')}</span>
                        <Link to='/applications/create'
                            className="btn btn-success create-btn-media">{`+ ${t('createNewApplication')}`}</Link>
                    </div>
                    <Tabs
                        id="applications-tab"
                        activeKey={applicationTab}
                        onSelect={(k) => {
                            setApplicationTab(k);
                        }}
                        className="mb-3"
                    >
                        <Tab eventKey="active" title={t('active')}>
                            <ApplicationList
                                applications={applications.filter(application => {
                                    return application.accepted === true;
                                })}
                                tab={t('activeApplications')}
                            />
                        </Tab>
                        <Tab eventKey="rejected" title={t('rejected')}>
                            <ApplicationList
                                applications={applications.filter(application => {
                                    return application.rejected === true;
                                })}
                                tab={t('rejectedApplications')}
                            />
                        </Tab>
                        <Tab eventKey="draft" title={t('draft')}>
                            <ApplicationList
                                applications={applications.filter(application => {
                                    return application.status === 'created' &&
                                        application.rejected === false &&
                                        application.accepted === false;
                                })}
                                tab={t('drafts')}
                            />
                        </Tab>
                        <Tab eventKey="verification" title={t('verification')}>
                            <ApplicationList
                                applications={applications.filter(application => {
                                    return application.status === 'in_progress' &&
                                        application.rejected === false &&
                                        application.accepted === false;
                                })}
                                tab={t('verificationApplications')}
                            />
                        </Tab>
                    </Tabs>
                </>
            );
        }
        if (!loading && applications.length === 0) {
            return <EmptyList />
        }
    }

    const ApplicationList = props => {
        let applications = props.applications;
        if (applications.length === 0) return (
            <span className="media-padding">{props.tab} {t('missing')}</span>
        )
        let cards = applications.map((application) => {
            return (
                <LazyLoad once={application.once}
                    height={200} offset={[-100, 0]}
                    key={application.id}>
                    <Card
                        application={application}
                        deleteApplication={deleteApplication}
                    />
                </LazyLoad>

            )
        })
        return (
            <div className="card-list">{cards}</div>
        )
    }

    const EmptyList = () => {
        return (
            <div className="empty-list">
                <img src={ApplicationSvg} alt={t('applicationIcon')} />
                <div className="d-flex flex-column align-items-center">
                    <span className="page-title">{t('myApplications')}</span>
                    <span className="text">{t('noApplications')}</span>
                </div>
                <Link to='/applications/create' className="btn btn-success">{t('createApplication')}</Link>
            </div>
        )
    }

    return (
        <>
            <Header applicationCount={applications.length} />
            <main>
                <div className="container">
                    {renderApplications()}
                </div>
                <div className={`loader-center ${loading ? '' : 'd-none'}`}>
                    <TailSpin color="grey" ariaLabel="loading-indicator" />
                </div>
                <ToastContainer />
            </main>
            <Footer />
        </>
    )
}

export default List;