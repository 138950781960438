import { t } from "i18next";
import Table from "rc-table";
import { useTranslation } from "react-i18next";
import '../../assets/scss/tables.scss'

export const LandBankTable = (props) => {
    const { t } = useTranslation();
    const columns = [
        {
            title: t('koatuu'),
            dataIndex: "koatuu",
            key: "koatuu",
            width: 200,
        },
        {
            title: t('settlement'),
            dataIndex: "settlement",
            key: "settlement",
            width: 500,
        },
        {
            title: t('area'),
            dataIndex: "area",
            key: "area",
            width: 200,
        },
        {
            title: t('count'),
            dataIndex: "count",
            key: "count",
            width: 200,
        }
    ]

    return (
        <Table
            columns={columns}
            data={props.data}
            tableLayout="auto"
            className="moderation-table"
            emptyText={t('noData')}
        />
    )
}

export const ExecProceedTable = (props) => {
    const columns = [
        {
            title: t('asep'),
            dataIndex: "id",
            key: "id",
            width: 200,
        },
        {
            title: t('subject'),
            dataIndex: "subject",
            key: "subject",
            width: 500,
        },
        {
            title: t('collector'),
            dataIndex: "debt_collector",
            key: "debt_collector",
            width: 200,
        },
        {
            title: t('openingDate'),
            dataIndex: "date",
            key: "date",
            width: 200,
        },
        {
            title: t('stateEP'),
            dataIndex: "status",
            key: "status",
            width: 200,
        }
    ]

    return (
        <Table
            columns={columns}
            data={props.data}
            tableLayout="auto"
            className="moderation-table"
            emptyText={t('noData')}
        />
    )
}

export const DebtorsTable = (props) => {
    const columns = [
        {
            title: t('role'),
            dataIndex: "role",
            key: "role",
            width: 200,
        },
        {
            title: t('lastname'),
            dataIndex: "name",
            key: "name",
            width: 500,
        },
        {
            title: t('data'),
            dataIndex: "info",
            key: "info",
            width: 400,
        }
    ]

    return (
        <Table
            columns={columns}
            data={props.data}
            tableLayout="auto"
            className="moderation-table"
            emptyText={t('noData')}
        />
    )
}

export const AgroReceiptsTable = (props) => {
    const columns = [
        {
            title: t('receiptType'),
            dataIndex: "type",
            key: "type",
            width: 250,
        },
        {
            title: t('number'),
            dataIndex: "number",
            key: "number",
            width: 100,
        },
        {
            title: t('certificateDate'),
            dataIndex: "date",
            key: "date",
            width: 250,
        },
        {
            title: t('debtors'),
            dataIndex: "debtor",
            key: "debtor",
            width: 300,
        },
        {
            title: t('commitmentDeadline'),
            dataIndex: "endDate",
            key: "endDate",
            width: 400,
        }
    ]

    return (
        <Table
            columns={columns}
            data={props.data}
            tableLayout="auto"
            className="moderation-table"
            emptyText={t('noData')}
        />
    )
}

export const BudgetTable = (props) => {
    const setColSpan = (index, value) => {
        switch (index) {
            case 2:
            case 4:
            case 7:
            case 9:
            case 12:
                return {
                    colSpan: value
                }
            default:
        }
    }

    const columns = [
        {
            title: t('selectedCategories'),
            dataIndex: "categories",
            key: "categories",
            width: 600,
        },
        {
            title: `${t('minBudget')}, $`,
            dataIndex: "minBudget",
            key: "min-budget",
            width: 100,
            className: "text-end",
            onCell(_, index) {
                return setColSpan(index, 5)
            }
        },
        {
            title: `${t('maxBudget')}, $`,
            dataIndex: "maxBudget",
            key: "max-budget",
            width: 100,
            className: "text-end",
            onCell(_, index) {
                return setColSpan(index, 0)
            }
        },
        {
            title: `${t('recommendBudget')}, $`,
            dataIndex: "recBudget",
            key: "rec-budget",
            width: 100,
            className: "text-end",
            onCell(_, index) {
                return setColSpan(index, 0)
            }
        },
        {
            title: `${t('assignedBudget')}, $`,
            dataIndex: "applyBudget",
            key: "apply-budget",
            width: 100,
            className: "text-end",
            onCell(_, index) {
                return setColSpan(index, 0)
            }
        }
    ]

    return (
        <Table
            columns={columns}
            data={props.data}
            tableLayout="auto"
            className="moderation-table budget-table"
            emptyText={t('noData')}
        />
    )
}

export const CompanyInfoTable = (props) => {
    const columns = [
        {
            title: `${t('landInUse')}, ${t('ha')}`,
            dataIndex: "area",
            key: "area",
            width: 300,
        },
        {
            title: t('activityType'),
            dataIndex: "activity",
            key: "activity",
            width: 300,
        },
        {
            title: `${t('sum')}, $`,
            dataIndex: "sum1",
            key: "sum1",
            width: 300,
            className: "text-end"
        },
        {
            title: `${t('sum')}, $`,
            dataIndex: "sum2",
            key: "sum2",
            width: 300,
            className: "text-end"
        }
    ]

    return (
        <Table
            columns={columns}
            data={props.data}
            tableLayout="auto"
            className="company-info-table"
            emptyText={t('noData')}
        />
    )
}