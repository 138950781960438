import { useTranslation } from "react-i18next"

export const AgreeCheckbox = (props) => {
    const { t } = useTranslation();

    return (
        <div className="form-group">
            <input className="form-control"
                name="agree"
                id="agree"
                type="checkbox"
                onChange={e => props.handleAgreeCheckbox(e.target.checked)}
            />
            <label htmlFor="agree">{t('agree')} 
                <a href="https://farmerhood.org/index.php/uk/terms-and-conditions-en"
                    className="text-underline"
                    target="_blank"
                    rel="noreferrer">
                    {t('ofConfident')}
                </a>
            </label>
        </div>
    )
}